import styled from "styled-components";
import { Link } from "react-router-dom";
import { defaults } from "theme";
import { type LogoTypes } from "./index";

export const LogoContainer = styled(Link)<{ $type: LogoTypes }>`
  flex-grow: 0;
  flex-shrink: 0;
  outline: 0;
  border: 0;
  display: block;
  border-radius: ${defaults.borderRadius}; // focus

  &:focus-visible {
    box-shadow: ${defaults.focusBoxShadowWhite};
  }

  @media (max-width: ${defaults.breakPoint}) {
    svg {
      width: ${(props) => (props.$type === "icon" && "inherit") || "108px"};
      height: ${(props) => (props.$type === "icon" && "inherit") || "30px"};
    }
  }
`;
