import styled, { css } from "styled-components";
import { NavLink as RRDNavLink } from "react-router-dom";
import { defaults, colors } from "theme";

export const NavContainer = styled.nav`
  margin-left: auto;
`;

export const NavInner = styled.div<{ $isActive: boolean }>`
  display: flex;

  @media (max-width: ${defaults.breakPoint}) {
    transition: opacity ${defaults.transition};
    opacity: ${(props) => (props.$isActive && "1") || "0"};
    pointer-events: ${(props) => (props.$isActive && "all") || "none"};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: #1c0e36;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const navButtonBase = css`
  outline: 0;
  border: 0;
  padding: 0;
  height: 30px;
  width: 36px;
  background: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity ${defaults.transition};

  &:hover {
    opacity: 0.5;
  }
`;

export const NavToggle = styled.button`
  display: none;

  @media (max-width: ${defaults.breakPoint}) {
    display: block;
    ${navButtonBase};
  }
`;

export const NavToggleBar = styled.div`
  width: 26px;
  height: 2px;
  background-color: #fff;

  & + & {
    margin-top: 6px;
  }
`;

export const NavClose = styled.button`
  display: none;

  @media (max-width: ${defaults.breakPoint}) {
    ${navButtonBase};
    position: absolute;
    right: 20px;
    top: 20px;
    padding-left: 1px;
  }
`;

export const navLinkBase = css`
  font-family: ${defaults.fontFamilyHeading};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: #fff;
  padding: 4px 1px;
`;

export const NavLink = styled(RRDNavLink)`
  ${navLinkBase};
  text-decoration: none;
  transition: color ${defaults.transition};
  outline: 0;
  border-radius: ${defaults.borderRadius}; // focus
  position: relative;

  &::after {
    content: "";
    background: linear-gradient(
      to right,
      ${colors.gradientBlue},
      ${colors.gradientPurple}
    );
    height: 1px;
    width: 100%;
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    width: 0;
    pointer-events: none;
    transition: width 300ms;
  }

  &:hover {
    &::after {
      width: 100%;
      left: 0;
      right: auto;
    }
  }

  &:focus-visible {
    box-shadow: ${defaults.focusBoxShadowWhite};
  }

  &.active {
    &::after {
      width: 100%;
      left: 0;
      right: auto;
    }

    &:hover {
      color: #fff;
    }
  }

  & + & {
    margin-left: 75px;
  }

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 22px;
    padding: 0 1px;

    & + & {
      margin: 25px 0 0;
    }
  }
`;

export const NavEmailContainer = styled.div`
  display: none;

  @media (max-width: ${defaults.breakPoint}) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
  }
`;

export const NavEmail = styled.a`
  @media (max-width: ${defaults.breakPoint}) {
    font-family: ${defaults.fontFamilyHeading};
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    display: block;
    transition: opacity ${defaults.transition};

    &:hover {
      opacity: 0.5;
    }
  }
`;
