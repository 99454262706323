import styled from "styled-components";
import { defaults, colors } from "theme";

export const HeadingThree = styled.h3`
  font-family: ${defaults.fontFamilyHeading};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.6;
  color: ${colors.blue};
  margin: 0 0 14px;
`;
