import { SvgRadialDots } from "svg/SvgRadialDots";
import { Logo } from "components/Logo";
import {
  RadialLayoutContainer,
  RadialLayoutHeading,
  RadialLayoutText,
  RadialLayoutInner,
  RadialLayoutGraphic,
  RadialLayoutLogo,
  RadialLayoutActions,
  RadialLayoutLink,
} from "./style";

type RadialLayoutAction = {
  label: string;
  link: string;
  key: string;
};

export type RadialLayoutProps = {
  heading: string;
  text?: string;
  actions?: RadialLayoutAction[];
};

export const RadialLayout = ({
  heading,
  text,
  actions = [],
}: RadialLayoutProps) => {
  const renderAction = ({ action }: { action: RadialLayoutAction }) => {
    return (
      <RadialLayoutLink to={action.link} key={action.key}>
        {action.label}
      </RadialLayoutLink>
    );
  };

  return (
    <RadialLayoutContainer>
      <RadialLayoutGraphic>
        <SvgRadialDots />
      </RadialLayoutGraphic>
      <RadialLayoutInner>
        <RadialLayoutLogo>
          <Logo />
        </RadialLayoutLogo>
        <RadialLayoutHeading>{heading}</RadialLayoutHeading>
        {text ? <RadialLayoutText>{text}</RadialLayoutText> : null}
        {actions.length ? (
          <RadialLayoutActions>
            {actions.map((action) => renderAction({ action }))}
          </RadialLayoutActions>
        ) : null}
      </RadialLayoutInner>
    </RadialLayoutContainer>
  );
};
