export const colors = {
  purple: "#7042FA",
  purple2: "#7C58FF",
  blue: "#6D8AFD",
  blue2: "#91A7FD",
  black: "#161317",
  text: "#C3C3C3",
  gradientBlue: "#6d8afd",
  gradientPurple: "#6d38fd",
};
