import { Meta } from "components/Meta";
import { RadialLayout } from "components/RadialLayout";

export const Error = () => {
  return (
    <main data-testid="error-page">
      <Meta title="Error" description="Page Not Found" />
      <RadialLayout
        heading="404"
        text="We can't find what you're looking for."
        actions={[
          {
            label: "Home",
            link: "/",
            key: "home",
          },
        ]}
      />
    </main>
  );
};
