import { Container } from "components/Container";
import { Nav } from "components/Nav";
import { Logo } from "components/Logo";
import { HeaderContainer, HeaderInner } from "./style";

export const Header = () => {
  return (
    <HeaderContainer data-testid="header">
      <Container>
        <HeaderInner>
          <Logo data-testid="header-logo" />
          <Nav />
        </HeaderInner>
      </Container>
    </HeaderContainer>
  );
};
