import styled from "styled-components";
import { defaults } from "theme";
import { HeadingThree, Text } from "components/Typography";
import stars from "images/stars_2x.png";

export const Info = styled.section`
  height: 810px;
  background-color: #1c0e36;
  text-align: center;
  background-color: #231244;
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url(${stars});
  background-position: 50% bottom;
  background-size: 2048px 782px;
  background-repeat: no-repeat;
  padding-top: 50px;

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 100px 0 40px;
    background-size: cover;
    background-position: 50% 50%;
  }
`;

export const InfoIntro = styled.div`
  max-width: 700px;
  margin-bottom: 75px;

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    margin-bottom: 50px;
  }
`;

export const InfoGrid = styled.div`
  width: 700px;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 60px;

  @media (max-width: ${defaults.breakPoint}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 50px;
  }

  @media (max-width: ${defaults.breakPointMd}) {
    width: auto;
    grid-template-columns: 1fr;
    text-align: center;
    column-gap: 0;
    row-gap: 0;
  }
`;

export const InfoGridItem = styled.div`
  margin-bottom: 35px;
`;

export const ContactSubHeading = styled(HeadingThree)`
  margin: 0 0 5px;
`;

export const ContactText = styled(Text)`
  font-size: 16px;
  margin: 0;
`;
