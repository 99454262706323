import styled from "styled-components";
import { defaults, colors } from "theme";

export const Text = styled.p`
  color: ${colors.text};
  margin: 0 0 30px;

  a {
    color: #fff;
    text-decoration: none;
    position: relative;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &::after {
      content: "";
      background: linear-gradient(
        to right,
        ${colors.gradientBlue},
        ${colors.gradientPurple}
      );
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: -3px;
      opacity: 0;
      pointer-events: none;
      transition: opacity ${defaults.transition} ease 0s;
    }
  }

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 16px;

    br {
      display: none;
    }
  }
`;
