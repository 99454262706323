import styled from "styled-components";
import { defaults } from "theme";

export const HeadingOne = styled.h1`
  font-family: ${defaults.fontFamilyHeading};
  font-size: 40px;
  font-weight: 400;
  line-height: 1.4;
  color: #fff;
  margin: 0 0 36px;

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 30px;
  }
`;
