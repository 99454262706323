import { useState, useEffect } from "react";
import { email } from "utils/info";
import { SvgNavClose } from "svg/SvgNavClose";
import {
  NavContainer,
  NavInner,
  NavLink,
  NavToggle,
  NavToggleBar,
  NavClose,
  NavEmailContainer,
  NavEmail,
} from "./style";

export const Nav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const mobileNavActiveClass = "mobile-nav-active";

  const onToggle = () => {
    // onToggle utilised in mobile view only.
    setShowMenu(!showMenu);

    if (showMenu) {
      document.body.classList.remove(mobileNavActiveClass);
    } else {
      document.body.classList.add(mobileNavActiveClass);
    }
  };

  const onClick = () => {
    // setShowMenu utilised in mobile view only.
    if (showMenu) {
      onToggle();
    }
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    // Edge case cleanup function.
    return function cleanup() {
      document.body.classList.remove(mobileNavActiveClass);
    };
  }, []);

  return (
    <NavContainer data-testid="nav">
      <NavToggle type="button" onClick={onToggle}>
        <NavToggleBar />
        <NavToggleBar />
        <NavToggleBar />
      </NavToggle>
      <NavInner $isActive={showMenu}>
        <NavClose type="button" onClick={onToggle}>
          <SvgNavClose />
        </NavClose>
        <NavLink to="/" onClick={onClick}>
          Home
        </NavLink>
        <NavLink to="/about" onClick={onClick}>
          About
        </NavLink>
        <NavLink to="/contact" onClick={onClick}>
          Contact
        </NavLink>
        <NavEmailContainer>
          <NavEmail href={`mailto:${email}`} target="_blank">
            {email}
          </NavEmail>
        </NavEmailContainer>
      </NavInner>
    </NavContainer>
  );
};
