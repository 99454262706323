import { ReactChildren } from "types/react";
import { ButtonLinkContainer } from "./style";

export type ButtonProps = {
  to: string;
  children: ReactChildren;
};

export const ButtonLink = ({ to, children, ...rest }: ButtonProps) => {
  return (
    <ButtonLinkContainer to={to} {...rest}>
      {children}
    </ButtonLinkContainer>
  );
};
