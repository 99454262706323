import { SvgLogo } from "svg/SvgLogo";
import { SvgLogoIcon } from "svg/SvgLogoIcon";
import { LogoContainer } from "./style";

export type LogoTypes = "default" | "icon";
type LogoProps = {
  type?: LogoTypes;
};

export const Logo = ({ type = "default", ...rest }: LogoProps) => {
  const onClick = () => {
    window.scrollTo({ top: 0 });
  };

  return (
    <LogoContainer to="/" {...rest} $type={type} onClick={onClick}>
      {type === "icon" ? <SvgLogoIcon /> : <SvgLogo />}
    </LogoContainer>
  );
};
