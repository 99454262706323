import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { defaults, colors } from "theme";
import { HeadingOne } from "components/Typography/HeadingOne";
import { Text } from "components/Typography/Text";

export const RadialLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  @media (max-width: ${defaults.breakPoint}) {
    position: relative;
    overflow: hidden;
  }
`;

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const RadialLayoutInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  animation: ${FadeIn} 700ms 50ms forwards;
`;

const ScaleFadeIn = keyframes`
  from {
    opacity: 0;
    transform: scale(0.85);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

export const RadialLayoutGraphic = styled.div`
  position: absolute;
  left: calc(50% - 330px);
  top: calc(50% - 330px);
  pointer-events: none;
  opacity: 0;
  animation: ${ScaleFadeIn} 700ms 300ms forwards;

  @media (max-width: ${defaults.breakPoint}) {
    left: calc(50% - 220px);
    top: calc(50% - 220px);

    svg {
      width: 440px;
      height: 440px;
    }
  }
`;

export const RadialLayoutHeading = styled(HeadingOne)`
  font-size: 46px;
  font-weight: 300;
  line-height: 1.8;
  margin: 0;
  color: #fff;

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 34px;
    line-height: 1.4;
    margin-bottom: 22px;
  }
`;

export const RadialLayoutText = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 10px;
  color: #7f7e91;

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 16px;
  }
`;

export const RadialLayoutActions = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  * + * {
    margin-top: 25px;
  }

  @media (max-width: ${defaults.breakPoint}) {
    margin-top: 25px;
  }
`;

export const RadialLayoutLink = styled(Link)`
  font-family: ${defaults.fontFamilyBody};
  line-height: 1.6;
  font-size: 20px;
  color: #fff;
  transition: color ${defaults.transition};

  &:hover {
    color: ${colors.purple};
  }

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 18px;
  }
`;

export const RadialLayoutLogo = styled.div`
  display: block;
  margin-bottom: 30px;

  svg {
    display: block;
  }
`;
