import { Routes } from "routing/Routes";
import { ThemeDefaults } from "./theme";
import "normalize.css";

function App() {
  return (
    <>
      <ThemeDefaults />
      <Routes />
    </>
  );
}

export default App;
