export const SvgLogoIcon = () => (
  <svg
    width={60}
    height={66}
    viewBox="0 0 60 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.385 21.366a11.61 11.61 0 0 0-8.978 4.236l-16.94-9.837c.52-1.328.786-2.74.786-4.165 0-6.166-4.834-11.254-11.005-11.583C6.077-.313.726 4.232.068 10.362c-.66 6.13 3.604 11.703 9.705 12.686v19.85C3.68 43.883-.575 49.452.085 55.574c.66 6.123 6.004 10.661 12.168 10.334 6.164-.327 10.995-5.406 11-11.564 0-1.425-.266-2.837-.786-4.165l16.923-9.854a11.645 11.645 0 0 0 14.125 3.04 11.59 11.59 0 0 0 6.181-13.03c-1.223-5.248-5.91-8.965-11.311-8.97ZM3.708 11.6c0-4.378 3.557-7.926 7.945-7.926s7.945 3.548 7.945 7.926c0 4.377-3.557 7.925-7.945 7.925S3.708 15.977 3.708 11.6Zm7.945 50.636c-4.388 0-7.945-3.548-7.945-7.926 0-4.377 3.557-7.925 7.945-7.925s7.945 3.548 7.945 7.925c0 2.107-.84 4.127-2.336 5.614a7.955 7.955 0 0 1-5.635 2.312h.026Zm8.952-15.261a11.624 11.624 0 0 0-7.124-4.078V23.04a11.623 11.623 0 0 0 7.124-4.077l16.922 9.836a11.318 11.318 0 0 0 0 8.331l-16.922 9.846Zm27.78-6.103a7.945 7.945 0 0 1-7.34-4.893 7.912 7.912 0 0 1 1.722-8.637 7.959 7.959 0 0 1 8.658-1.718 7.925 7.925 0 0 1 4.905 7.322c0 4.377-3.557 7.926-7.945 7.926Z"
      fill="url(#svgLogoIcon_a)"
    />
    <defs>
      <linearGradient
        id="svgLogoIcon_a"
        x1={2.366}
        y1={49.845}
        x2={31.833}
        y2={25.497}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6D8AFD" />
        <stop offset={1} stopColor="#6D38FD" />
      </linearGradient>
    </defs>
  </svg>
);
