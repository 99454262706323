import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { defaults } from "theme";

const ButtonBaseStyle = css`
  font-family: ${defaults.fontFamilyBody};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  background: none;
  border: none;
  width: 200px;
  height: 50px;
  border-radius: 3px;
  position: relative;
  padding: 0 12px;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;

  &::before,
  &::after {
    content: "";
    border-radius: 3px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }

  &::before {
    background: linear-gradient(to right, #6d8afd, #6d38fd);
  }

  &::after {
    background: linear-gradient(to right, #92a7fe, #8356fd);
    opacity: 0;
    transition: opacity ${defaults.transition};
  }

  &:focus-visible {
    box-shadow: ${defaults.focusBoxShadowWhite};
  }

  &:hover {
    background: #6d38fd;

    &::after {
      opacity: 1;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const ButtonContainer = styled.button`
  ${ButtonBaseStyle};
`;

export const ButtonLinkContainer = styled(Link)`
  ${ButtonBaseStyle};
  text-decoration: none;
`;
