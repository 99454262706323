import { ReactChildren } from "types/react";
import { Header } from "components/Header";
import { Footer } from "components/Footer";

type PageProps = {
  children: ReactChildren;
};

export const Page = ({ children }: PageProps) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
