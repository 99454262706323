import { BrowserRouter, Routes as RRDRoutes, Route } from "react-router-dom";
import { Page } from "components/Page";
import { Home } from "pages/Home";
import { About } from "pages/About";
import { Contact } from "pages/Contact";
import { Error } from "pages/Error";

export const Routes = () => {
  const renderPageRoute = ({
    path,
    page,
  }: {
    path: string;
    page: JSX.Element;
  }) => {
    return <Route path={path} element={<Page>{page}</Page>} />;
  };

  return (
    <BrowserRouter>
      <RRDRoutes>
        {renderPageRoute({ path: "/", page: <Home /> })}
        {renderPageRoute({ path: "/about", page: <About /> })}
        {renderPageRoute({ path: "/contact", page: <Contact /> })}
        <Route path="*" element={<Error />} />
      </RRDRoutes>
    </BrowserRouter>
  );
};
