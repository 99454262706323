import styled from "styled-components";
import { defaults } from "theme";
import bubbles from "images/bubbles.jpg";
import bubblesAlt from "images/bubbles_alt.jpg";

export const Hero = styled.section`
  height: 900px;
  background-color: #231244;
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url(${bubbles});
  background-position: calc(50% - 100px) bottom;
  background-repeat: no-repeat;

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 100px 0 40px;
    background-size: cover;
    background-position: calc(50% + 250px) bottom;
  }
`;

export const HeroInner = styled.div`
  max-width: 700px;
  padding-bottom: 20px;

  @media (max-width: ${defaults.breakPoint}) {
    padding: 0;
  }
`;

export const About = styled.section`
  padding: 125px 0 165px;
  background-color: #1c0e36;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: ${defaults.breakPoint}) {
    padding: 65px 0 75px;
  }
`;

export const AboutIntro = styled.div`
  max-width: 700px;
  margin-bottom: 65px;

  h2 {
    margin-bottom: 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    margin-bottom: 50px;
  }
`;

export const AboutGrid = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 70px;
  row-gap: 70px;

  p {
    font-size: 16px;
    margin-bottom: 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 50px;
  }

  @media (max-width: ${defaults.breakPointMd}) {
    grid-template-columns: 1fr;
    text-align: center;
    column-gap: 0;
    row-gap: 50px;
  }
`;

export const AboutGridItem = styled.div`
  @media (max-width: ${defaults.breakPointMd}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const AboutGridIcon = styled.div`
  height: 120px;

  @media (max-width: ${defaults.breakPoint}) {
    margin: 0 auto;

    img {
      left: 0 !important;
    }
  }
`;

export const Banner = styled.section`
  height: 500px;
  background-color: #231244;
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url(${bubblesAlt});
  background-position: calc(50% + 275px) calc(50%);
  background-repeat: no-repeat;

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 65px 0 80px;
    background-position: calc(50% - 800px) 50%;
  }
`;

export const BannerButton = styled.div`
  margin-top: 50px;

  @media (max-width: ${defaults.breakPoint}) {
    margin-top: 45px;
  }
`;
