import styled from "styled-components";
import { defaults, colors } from "theme";

export const FooterContainer = styled.footer`
  background-color: #1c0e36;
  font-family: ${defaults.fontFamilyHeading};
`;

export const FooterInner = styled.div<{ $isContactPage: boolean }>`
  height: ${(props) => (props.$isContactPage && "340px") || "400px"};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${defaults.breakPoint}) {
    padding: 50px 0;
    height: auto;
  }
`;

export const FooterLogo = styled.div`
  margin-bottom: 40px;
`;

export const FooterText = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin: 0;

  a {
    color: #fff;
    text-decoration: none;
    display: block;
    outline: 0;
    border: 0;
    border-radius: ${defaults.borderRadius}; // focus
    position: relative;

    &:hover {
      &::after {
        opacity: 1;
      }
    }

    &:focus-visible {
      box-shadow: ${defaults.focusBoxShadowWhite};
    }

    &::after {
      content: "";
      background: linear-gradient(
        to right,
        ${colors.gradientBlue},
        ${colors.gradientPurple}
      );
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 2px;
      opacity: 0;
      pointer-events: none;
      transition: opacity ${defaults.transition} ease 0s;
    }
  }

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 16px;

    & + & {
      margin-top: 10px;
    }

    a {
      display: inline-block;
    }
  }
`;

export const FooterInfo = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 75px;
  padding-top: 10px;
  margin-bottom: 40px;

  p + p {
    margin-left: 90px;
  }

  @media (max-width: ${defaults.breakPoint}) {
    display: block;
    padding: 0;
    text-align: center;

    p + p {
      margin-left: 0;
    }
  }
`;

export const FooterCopyright = styled.div`
  p {
    font-size: 17px;
  }

  @media (max-width: ${defaults.breakPoint}) {
    p {
      font-size: 16px;
    }
  }
`;
