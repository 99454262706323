import { Meta } from "components/Meta";
import { Container } from "components/Container";
import { HeadingOne, HeadingTwo, Text } from "components/Typography";
import { ButtonLink } from "components/Button";
// import { JobsGrid } from "./JobsGrid";
import { JobsNoOpenings } from "./JobsNoOpenings";
import {
  Hero,
  HeroInner,
  Jobs,
  JobsIntro,
  Banner,
  BannerInner,
  BannerButton,
  HeartContainer,
} from "./style";

export const About = () => (
  <main data-testid="about-page">
    <Meta
      title="About"
      description="Our mission is to advance business productivity by enabling companies to unify their people, systems, and data with elegant technology solutions. We're advocates of the data-centric manifesto."
    />
    <Hero data-testid="about-hero">
      <Container>
        <HeroInner>
          <HeartContainer>
            <HeadingOne>We believe in unity.</HeadingOne>
          </HeartContainer>
          <Text>
            Our mission is to advance business productivity by enabling
            companies to unify their people, systems, and data with elegant
            technology solutions. We&#39;re advocates of the data-centric
            manifesto based in Auckland, New Zealand.
          </Text>
        </HeroInner>
      </Container>
    </Hero>
    <Jobs data-testid="about-jobs">
      <Container>
        <JobsIntro>
          <HeadingTwo>Current career openings</HeadingTwo>
          <Text>
            We are always looking for talented people to join us here at Limber.
            We&apos;re passionate about building an amazing product and helping
            our customers use it to transform they way they manage their data.
            If that sounds like something you&apos;d like to be a part of,
            we&apos;d love to hear from you.
          </Text>
          <JobsNoOpenings />
        </JobsIntro>
        {/* <JobsGrid /> */}
      </Container>
    </Jobs>
    <Banner data-testid="about-banner">
      <Container>
        <BannerInner>
          <HeadingTwo>Interested in working with us?</HeadingTwo>
          <Text>We&apos;d like to hear from you. Get in touch.</Text>
          <BannerButton>
            <ButtonLink to="/contact">Contact Us</ButtonLink>
          </BannerButton>
        </BannerInner>
      </Container>
    </Banner>
  </main>
);
