import { ReactStyle } from "types/react";

type Props = {
  style?: ReactStyle;
};

export const SvgRadialDots = (props: Props) => (
  <svg
    width={660}
    height={660}
    viewBox="0 0 660 660"
    fill="#3E3E6B"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={658.5} cy={334.5} r={1.5} />
    <circle cx={1.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={1.5} r={1.5} />
    <circle cx={325.5} cy={658.5} r={1.5} />
    <circle cx={658.5} cy={343.5} r={1.5} />
    <circle cx={1.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={1.5} r={1.5} />
    <circle cx={316.5} cy={658.5} r={1.5} />
    <circle cx={604.5} cy={334.5} r={1.5} />
    <circle cx={55.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={55.5} r={1.5} />
    <circle cx={325.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={343.5} r={1.5} />
    <circle cx={55.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={55.5} r={1.5} />
    <circle cx={316.5} cy={604.5} r={1.5} />
    <circle cx={631.5} cy={334.5} r={1.5} />
    <circle cx={28.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={28.5} r={1.5} />
    <circle cx={325.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={343.5} r={1.5} />
    <circle cx={28.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={28.5} r={1.5} />
    <circle cx={316.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={334.5} r={1.5} />
    <circle cx={82.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={82.5} r={1.5} />
    <circle cx={325.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={343.5} r={1.5} />
    <circle cx={82.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={82.5} r={1.5} />
    <circle cx={316.5} cy={577.5} r={1.5} />
    <circle cx={649.5} cy={334.5} r={1.5} />
    <circle cx={10.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={10.5} r={1.5} />
    <circle cx={325.5} cy={649.5} r={1.5} />
    <circle cx={649.5} cy={343.5} r={1.5} />
    <circle cx={10.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={10.5} r={1.5} />
    <circle cx={316.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={334.5} r={1.5} />
    <circle cx={64.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={64.5} r={1.5} />
    <circle cx={325.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={343.5} r={1.5} />
    <circle cx={64.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={64.5} r={1.5} />
    <circle cx={316.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={334.5} r={1.5} />
    <circle cx={37.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={37.5} r={1.5} />
    <circle cx={325.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={343.5} r={1.5} />
    <circle cx={37.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={37.5} r={1.5} />
    <circle cx={316.5} cy={622.5} r={1.5} />
    <circle cx={640.5} cy={334.5} r={1.5} />
    <circle cx={19.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={19.5} r={1.5} />
    <circle cx={325.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={343.5} r={1.5} />
    <circle cx={19.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={19.5} r={1.5} />
    <circle cx={316.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={334.5} r={1.5} />
    <circle cx={73.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={73.5} r={1.5} />
    <circle cx={325.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={343.5} r={1.5} />
    <circle cx={73.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={73.5} r={1.5} />
    <circle cx={316.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={334.5} r={1.5} />
    <circle cx={46.5} cy={325.5} r={1.5} />
    <circle cx={334.5} cy={46.5} r={1.5} />
    <circle cx={325.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={343.5} r={1.5} />
    <circle cx={46.5} cy={316.5} r={1.5} />
    <circle cx={343.5} cy={46.5} r={1.5} />
    <circle cx={316.5} cy={613.5} r={1.5} />
    <circle cx={658.5} cy={352.5} r={1.5} />
    <circle cx={1.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={1.5} r={1.5} />
    <circle cx={307.5} cy={658.5} r={1.5} />
    <circle cx={658.5} cy={361.5} r={1.5} />
    <circle cx={1.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={1.5} r={1.5} />
    <circle cx={298.5} cy={658.5} r={1.5} />
    <circle cx={604.5} cy={352.5} r={1.5} />
    <circle cx={55.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={55.5} r={1.5} />
    <circle cx={307.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={361.5} r={1.5} />
    <circle cx={55.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={55.5} r={1.5} />
    <circle cx={298.5} cy={604.5} r={1.5} />
    <circle cx={631.5} cy={352.5} r={1.5} />
    <circle cx={28.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={28.5} r={1.5} />
    <circle cx={307.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={361.5} r={1.5} />
    <circle cx={28.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={28.5} r={1.5} />
    <circle cx={298.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={352.5} r={1.5} />
    <circle cx={82.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={82.5} r={1.5} />
    <circle cx={307.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={361.5} r={1.5} />
    <circle cx={82.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={82.5} r={1.5} />
    <circle cx={298.5} cy={577.5} r={1.5} />
    <circle cx={649.5} cy={352.5} r={1.5} />
    <circle cx={10.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={10.5} r={1.5} />
    <circle cx={307.5} cy={649.5} r={1.5} />
    <circle cx={649.5} cy={361.5} r={1.5} />
    <circle cx={10.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={10.5} r={1.5} />
    <circle cx={298.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={352.5} r={1.5} />
    <circle cx={64.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={64.5} r={1.5} />
    <circle cx={307.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={361.5} r={1.5} />
    <circle cx={64.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={64.5} r={1.5} />
    <circle cx={298.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={352.5} r={1.5} />
    <circle cx={37.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={37.5} r={1.5} />
    <circle cx={307.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={361.5} r={1.5} />
    <circle cx={37.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={37.5} r={1.5} />
    <circle cx={298.5} cy={622.5} r={1.5} />
    <circle cx={640.5} cy={352.5} r={1.5} />
    <circle cx={19.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={19.5} r={1.5} />
    <circle cx={307.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={361.5} r={1.5} />
    <circle cx={19.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={19.5} r={1.5} />
    <circle cx={298.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={352.5} r={1.5} />
    <circle cx={73.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={73.5} r={1.5} />
    <circle cx={307.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={361.5} r={1.5} />
    <circle cx={73.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={73.5} r={1.5} />
    <circle cx={298.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={352.5} r={1.5} />
    <circle cx={46.5} cy={307.5} r={1.5} />
    <circle cx={352.5} cy={46.5} r={1.5} />
    <circle cx={307.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={361.5} r={1.5} />
    <circle cx={46.5} cy={298.5} r={1.5} />
    <circle cx={361.5} cy={46.5} r={1.5} />
    <circle cx={298.5} cy={613.5} r={1.5} />
    <circle cx={658.5} cy={370.5} r={1.5} />
    <circle cx={1.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={1.5} r={1.5} />
    <circle cx={289.5} cy={658.5} r={1.5} />
    <circle cx={658.5} cy={379.5} r={1.5} />
    <circle cx={1.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={1.5} r={1.5} />
    <circle cx={280.5} cy={658.5} r={1.5} />
    <circle cx={604.5} cy={370.5} r={1.5} />
    <circle cx={55.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={55.5} r={1.5} />
    <circle cx={289.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={379.5} r={1.5} />
    <circle cx={55.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={55.5} r={1.5} />
    <circle cx={280.5} cy={604.5} r={1.5} />
    <circle cx={631.5} cy={370.5} r={1.5} />
    <circle cx={28.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={28.5} r={1.5} />
    <circle cx={289.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={379.5} r={1.5} />
    <circle cx={28.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={28.5} r={1.5} />
    <circle cx={280.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={370.5} r={1.5} />
    <circle cx={82.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={82.5} r={1.5} />
    <circle cx={289.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={379.5} r={1.5} />
    <circle cx={82.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={82.5} r={1.5} />
    <circle cx={280.5} cy={577.5} r={1.5} />
    <circle cx={649.5} cy={370.5} r={1.5} />
    <circle cx={10.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={10.5} r={1.5} />
    <circle cx={289.5} cy={649.5} r={1.5} />
    <circle cx={649.5} cy={379.5} r={1.5} />
    <circle cx={10.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={10.5} r={1.5} />
    <circle cx={280.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={370.5} r={1.5} />
    <circle cx={64.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={64.5} r={1.5} />
    <circle cx={289.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={379.5} r={1.5} />
    <circle cx={64.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={64.5} r={1.5} />
    <circle cx={280.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={370.5} r={1.5} />
    <circle cx={37.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={37.5} r={1.5} />
    <circle cx={289.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={379.5} r={1.5} />
    <circle cx={37.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={37.5} r={1.5} />
    <circle cx={280.5} cy={622.5} r={1.5} />
    <circle cx={640.5} cy={370.5} r={1.5} />
    <circle cx={19.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={19.5} r={1.5} />
    <circle cx={289.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={379.5} r={1.5} />
    <circle cx={19.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={19.5} r={1.5} />
    <circle cx={280.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={370.5} r={1.5} />
    <circle cx={73.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={73.5} r={1.5} />
    <circle cx={289.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={379.5} r={1.5} />
    <circle cx={73.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={73.5} r={1.5} />
    <circle cx={280.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={370.5} r={1.5} />
    <circle cx={46.5} cy={289.5} r={1.5} />
    <circle cx={370.5} cy={46.5} r={1.5} />
    <circle cx={289.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={379.5} r={1.5} />
    <circle cx={46.5} cy={280.5} r={1.5} />
    <circle cx={379.5} cy={46.5} r={1.5} />
    <circle cx={280.5} cy={613.5} r={1.5} />
    <circle cx={658.5} cy={388.5} r={1.5} />
    <circle cx={1.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={1.5} r={1.5} />
    <circle cx={271.5} cy={658.5} r={1.5} />
    <circle cx={604.5} cy={388.5} r={1.5} />
    <circle cx={55.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={55.5} r={1.5} />
    <circle cx={271.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={397.5} r={1.5} />
    <circle cx={55.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={55.5} r={1.5} />
    <circle cx={262.5} cy={604.5} r={1.5} />
    <circle cx={631.5} cy={388.5} r={1.5} />
    <circle cx={28.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={28.5} r={1.5} />
    <circle cx={271.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={397.5} r={1.5} />
    <circle cx={28.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={28.5} r={1.5} />
    <circle cx={262.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={388.5} r={1.5} />
    <circle cx={82.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={82.5} r={1.5} />
    <circle cx={271.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={397.5} r={1.5} />
    <circle cx={82.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={82.5} r={1.5} />
    <circle cx={262.5} cy={577.5} r={1.5} />
    <circle cx={568.5} cy={397.5} r={1.5} />
    <circle cx={91.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={91.5} r={1.5} />
    <circle cx={262.5} cy={568.5} r={1.5} />
    <circle cx={649.5} cy={388.5} r={1.5} />
    <circle cx={10.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={10.5} r={1.5} />
    <circle cx={271.5} cy={649.5} r={1.5} />
    <circle cx={649.5} cy={397.5} r={1.5} />
    <circle cx={10.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={10.5} r={1.5} />
    <circle cx={262.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={388.5} r={1.5} />
    <circle cx={64.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={64.5} r={1.5} />
    <circle cx={271.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={397.5} r={1.5} />
    <circle cx={64.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={64.5} r={1.5} />
    <circle cx={262.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={388.5} r={1.5} />
    <circle cx={37.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={37.5} r={1.5} />
    <circle cx={271.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={397.5} r={1.5} />
    <circle cx={37.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={37.5} r={1.5} />
    <circle cx={262.5} cy={622.5} r={1.5} />
    <circle cx={640.5} cy={388.5} r={1.5} />
    <circle cx={19.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={19.5} r={1.5} />
    <circle cx={271.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={397.5} r={1.5} />
    <circle cx={19.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={19.5} r={1.5} />
    <circle cx={262.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={388.5} r={1.5} />
    <circle cx={73.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={73.5} r={1.5} />
    <circle cx={271.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={397.5} r={1.5} />
    <circle cx={73.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={73.5} r={1.5} />
    <circle cx={262.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={388.5} r={1.5} />
    <circle cx={46.5} cy={271.5} r={1.5} />
    <circle cx={388.5} cy={46.5} r={1.5} />
    <circle cx={271.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={397.5} r={1.5} />
    <circle cx={46.5} cy={262.5} r={1.5} />
    <circle cx={397.5} cy={46.5} r={1.5} />
    <circle cx={262.5} cy={613.5} r={1.5} />
    <circle cx={649.5} cy={406.5} r={1.5} />
    <circle cx={10.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={10.5} r={1.5} />
    <circle cx={253.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={406.5} r={1.5} />
    <circle cx={64.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={64.5} r={1.5} />
    <circle cx={253.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={433.5} r={1.5} />
    <circle cx={64.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={64.5} r={1.5} />
    <circle cx={226.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={406.5} r={1.5} />
    <circle cx={37.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={37.5} r={1.5} />
    <circle cx={253.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={433.5} r={1.5} />
    <circle cx={37.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={37.5} r={1.5} />
    <circle cx={226.5} cy={622.5} r={1.5} />
    <circle cx={568.5} cy={406.5} r={1.5} />
    <circle cx={91.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={91.5} r={1.5} />
    <circle cx={253.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={433.5} r={1.5} />
    <circle cx={91.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={91.5} r={1.5} />
    <circle cx={226.5} cy={568.5} r={1.5} />
    <circle cx={640.5} cy={406.5} r={1.5} />
    <circle cx={19.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={19.5} r={1.5} />
    <circle cx={253.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={433.5} r={1.5} />
    <circle cx={19.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={19.5} r={1.5} />
    <circle cx={226.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={406.5} r={1.5} />
    <circle cx={73.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={73.5} r={1.5} />
    <circle cx={253.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={433.5} r={1.5} />
    <circle cx={73.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={73.5} r={1.5} />
    <circle cx={226.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={406.5} r={1.5} />
    <circle cx={46.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={46.5} r={1.5} />
    <circle cx={253.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={433.5} r={1.5} />
    <circle cx={46.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={46.5} r={1.5} />
    <circle cx={226.5} cy={613.5} r={1.5} />
    <circle cx={631.5} cy={406.5} r={1.5} />
    <circle cx={28.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={28.5} r={1.5} />
    <circle cx={253.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={433.5} r={1.5} />
    <circle cx={28.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={28.5} r={1.5} />
    <circle cx={226.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={406.5} r={1.5} />
    <circle cx={82.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={82.5} r={1.5} />
    <circle cx={253.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={433.5} r={1.5} />
    <circle cx={82.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={82.5} r={1.5} />
    <circle cx={226.5} cy={577.5} r={1.5} />
    <circle cx={604.5} cy={406.5} r={1.5} />
    <circle cx={55.5} cy={253.5} r={1.5} />
    <circle cx={406.5} cy={55.5} r={1.5} />
    <circle cx={253.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={433.5} r={1.5} />
    <circle cx={55.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={55.5} r={1.5} />
    <circle cx={226.5} cy={604.5} r={1.5} />
    <circle cx={649.5} cy={415.5} r={1.5} />
    <circle cx={10.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={10.5} r={1.5} />
    <circle cx={244.5} cy={649.5} r={1.5} />
    <circle cx={649.5} cy={424.5} r={1.5} />
    <circle cx={10.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={10.5} r={1.5} />
    <circle cx={235.5} cy={649.5} r={1.5} />
    <circle cx={595.5} cy={415.5} r={1.5} />
    <circle cx={64.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={64.5} r={1.5} />
    <circle cx={244.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={442.5} r={1.5} />
    <circle cx={64.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={64.5} r={1.5} />
    <circle cx={217.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={424.5} r={1.5} />
    <circle cx={64.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={64.5} r={1.5} />
    <circle cx={235.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={451.5} r={1.5} />
    <circle cx={64.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={64.5} r={1.5} />
    <circle cx={208.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={415.5} r={1.5} />
    <circle cx={37.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={37.5} r={1.5} />
    <circle cx={244.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={442.5} r={1.5} />
    <circle cx={37.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={37.5} r={1.5} />
    <circle cx={217.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={424.5} r={1.5} />
    <circle cx={37.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={37.5} r={1.5} />
    <circle cx={235.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={451.5} r={1.5} />
    <circle cx={37.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={37.5} r={1.5} />
    <circle cx={208.5} cy={622.5} r={1.5} />
    <circle cx={568.5} cy={415.5} r={1.5} />
    <circle cx={91.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={91.5} r={1.5} />
    <circle cx={244.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={442.5} r={1.5} />
    <circle cx={91.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={91.5} r={1.5} />
    <circle cx={217.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={424.5} r={1.5} />
    <circle cx={91.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={91.5} r={1.5} />
    <circle cx={235.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={451.5} r={1.5} />
    <circle cx={91.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={91.5} r={1.5} />
    <circle cx={208.5} cy={568.5} r={1.5} />
    <circle cx={640.5} cy={415.5} r={1.5} />
    <circle cx={19.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={19.5} r={1.5} />
    <circle cx={244.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={442.5} r={1.5} />
    <circle cx={19.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={19.5} r={1.5} />
    <circle cx={217.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={424.5} r={1.5} />
    <circle cx={19.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={19.5} r={1.5} />
    <circle cx={235.5} cy={640.5} r={1.5} />
    <circle cx={640.5} cy={451.5} r={1.5} />
    <circle cx={19.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={19.5} r={1.5} />
    <circle cx={208.5} cy={640.5} r={1.5} />
    <circle cx={586.5} cy={415.5} r={1.5} />
    <circle cx={73.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={73.5} r={1.5} />
    <circle cx={244.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={442.5} r={1.5} />
    <circle cx={73.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={73.5} r={1.5} />
    <circle cx={217.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={424.5} r={1.5} />
    <circle cx={73.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={73.5} r={1.5} />
    <circle cx={235.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={451.5} r={1.5} />
    <circle cx={73.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={73.5} r={1.5} />
    <circle cx={208.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={415.5} r={1.5} />
    <circle cx={46.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={46.5} r={1.5} />
    <circle cx={244.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={442.5} r={1.5} />
    <circle cx={46.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={46.5} r={1.5} />
    <circle cx={217.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={424.5} r={1.5} />
    <circle cx={46.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={46.5} r={1.5} />
    <circle cx={235.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={451.5} r={1.5} />
    <circle cx={46.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={46.5} r={1.5} />
    <circle cx={208.5} cy={613.5} r={1.5} />
    <circle cx={631.5} cy={415.5} r={1.5} />
    <circle cx={28.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={28.5} r={1.5} />
    <circle cx={244.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={442.5} r={1.5} />
    <circle cx={28.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={28.5} r={1.5} />
    <circle cx={217.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={424.5} r={1.5} />
    <circle cx={28.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={28.5} r={1.5} />
    <circle cx={235.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={451.5} r={1.5} />
    <circle cx={28.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={28.5} r={1.5} />
    <circle cx={208.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={415.5} r={1.5} />
    <circle cx={82.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={82.5} r={1.5} />
    <circle cx={244.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={442.5} r={1.5} />
    <circle cx={82.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={82.5} r={1.5} />
    <circle cx={217.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={424.5} r={1.5} />
    <circle cx={82.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={82.5} r={1.5} />
    <circle cx={235.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={451.5} r={1.5} />
    <circle cx={82.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={82.5} r={1.5} />
    <circle cx={208.5} cy={577.5} r={1.5} />
    <circle cx={604.5} cy={415.5} r={1.5} />
    <circle cx={55.5} cy={244.5} r={1.5} />
    <circle cx={415.5} cy={55.5} r={1.5} />
    <circle cx={244.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={442.5} r={1.5} />
    <circle cx={55.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={55.5} r={1.5} />
    <circle cx={217.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={424.5} r={1.5} />
    <circle cx={55.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={55.5} r={1.5} />
    <circle cx={235.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={451.5} r={1.5} />
    <circle cx={55.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={55.5} r={1.5} />
    <circle cx={208.5} cy={604.5} r={1.5} />
    <circle cx={586.5} cy={460.5} r={1.5} />
    <circle cx={73.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={73.5} r={1.5} />
    <circle cx={199.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={460.5} r={1.5} />
    <circle cx={46.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={46.5} r={1.5} />
    <circle cx={199.5} cy={613.5} r={1.5} />
    <circle cx={559.5} cy={460.5} r={1.5} />
    <circle cx={100.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={100.5} r={1.5} />
    <circle cx={199.5} cy={559.5} r={1.5} />
    <circle cx={631.5} cy={460.5} r={1.5} />
    <circle cx={28.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={28.5} r={1.5} />
    <circle cx={199.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={460.5} r={1.5} />
    <circle cx={82.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={82.5} r={1.5} />
    <circle cx={199.5} cy={577.5} r={1.5} />
    <circle cx={604.5} cy={460.5} r={1.5} />
    <circle cx={55.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={55.5} r={1.5} />
    <circle cx={199.5} cy={604.5} r={1.5} />
    <circle cx={622.5} cy={460.5} r={1.5} />
    <circle cx={37.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={37.5} r={1.5} />
    <circle cx={199.5} cy={622.5} r={1.5} />
    <circle cx={568.5} cy={460.5} r={1.5} />
    <circle cx={91.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={91.5} r={1.5} />
    <circle cx={199.5} cy={568.5} r={1.5} />
    <circle cx={595.5} cy={460.5} r={1.5} />
    <circle cx={64.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={64.5} r={1.5} />
    <circle cx={199.5} cy={595.5} r={1.5} />
    <circle cx={586.5} cy={469.5} r={1.5} />
    <circle cx={73.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={73.5} r={1.5} />
    <circle cx={190.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={478.5} r={1.5} />
    <circle cx={73.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={73.5} r={1.5} />
    <circle cx={181.5} cy={586.5} r={1.5} />
    <circle cx={613.5} cy={469.5} r={1.5} />
    <circle cx={46.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={46.5} r={1.5} />
    <circle cx={190.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={478.5} r={1.5} />
    <circle cx={46.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={46.5} r={1.5} />
    <circle cx={181.5} cy={613.5} r={1.5} />
    <circle cx={559.5} cy={469.5} r={1.5} />
    <circle cx={100.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={100.5} r={1.5} />
    <circle cx={190.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={478.5} r={1.5} />
    <circle cx={100.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={100.5} r={1.5} />
    <circle cx={181.5} cy={559.5} r={1.5} />
    <circle cx={631.5} cy={469.5} r={1.5} />
    <circle cx={28.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={28.5} r={1.5} />
    <circle cx={190.5} cy={631.5} r={1.5} />
    <circle cx={631.5} cy={478.5} r={1.5} />
    <circle cx={28.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={28.5} r={1.5} />
    <circle cx={181.5} cy={631.5} r={1.5} />
    <circle cx={577.5} cy={469.5} r={1.5} />
    <circle cx={82.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={82.5} r={1.5} />
    <circle cx={190.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={478.5} r={1.5} />
    <circle cx={82.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={82.5} r={1.5} />
    <circle cx={181.5} cy={577.5} r={1.5} />
    <circle cx={604.5} cy={469.5} r={1.5} />
    <circle cx={55.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={55.5} r={1.5} />
    <circle cx={190.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={478.5} r={1.5} />
    <circle cx={55.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={55.5} r={1.5} />
    <circle cx={181.5} cy={604.5} r={1.5} />
    <circle cx={622.5} cy={469.5} r={1.5} />
    <circle cx={37.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={37.5} r={1.5} />
    <circle cx={190.5} cy={622.5} r={1.5} />
    <circle cx={622.5} cy={478.5} r={1.5} />
    <circle cx={37.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={37.5} r={1.5} />
    <circle cx={181.5} cy={622.5} r={1.5} />
    <circle cx={568.5} cy={469.5} r={1.5} />
    <circle cx={91.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={91.5} r={1.5} />
    <circle cx={190.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={478.5} r={1.5} />
    <circle cx={91.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={91.5} r={1.5} />
    <circle cx={181.5} cy={568.5} r={1.5} />
    <circle cx={595.5} cy={469.5} r={1.5} />
    <circle cx={64.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={64.5} r={1.5} />
    <circle cx={190.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={478.5} r={1.5} />
    <circle cx={64.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={64.5} r={1.5} />
    <circle cx={181.5} cy={595.5} r={1.5} />
    <circle cx={622.5} cy={487.5} r={1.5} />
    <circle cx={37.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={37.5} r={1.5} />
    <circle cx={172.5} cy={622.5} r={1.5} />
    <circle cx={568.5} cy={487.5} r={1.5} />
    <circle cx={91.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={91.5} r={1.5} />
    <circle cx={172.5} cy={568.5} r={1.5} />
    <circle cx={595.5} cy={487.5} r={1.5} />
    <circle cx={64.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={64.5} r={1.5} />
    <circle cx={172.5} cy={595.5} r={1.5} />
    <circle cx={541.5} cy={487.5} r={1.5} />
    <circle cx={118.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={118.5} r={1.5} />
    <circle cx={172.5} cy={541.5} r={1.5} />
    <circle cx={613.5} cy={487.5} r={1.5} />
    <circle cx={46.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={46.5} r={1.5} />
    <circle cx={172.5} cy={613.5} r={1.5} />
    <circle cx={559.5} cy={487.5} r={1.5} />
    <circle cx={100.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={100.5} r={1.5} />
    <circle cx={172.5} cy={559.5} r={1.5} />
    <circle cx={586.5} cy={487.5} r={1.5} />
    <circle cx={73.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={73.5} r={1.5} />
    <circle cx={172.5} cy={586.5} r={1.5} />
    <circle cx={604.5} cy={487.5} r={1.5} />
    <circle cx={55.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={55.5} r={1.5} />
    <circle cx={172.5} cy={604.5} r={1.5} />
    <circle cx={550.5} cy={487.5} r={1.5} />
    <circle cx={109.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={109.5} r={1.5} />
    <circle cx={172.5} cy={550.5} r={1.5} />
    <circle cx={577.5} cy={487.5} r={1.5} />
    <circle cx={82.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={82.5} r={1.5} />
    <circle cx={172.5} cy={577.5} r={1.5} />
    <circle cx={568.5} cy={496.5} r={1.5} />
    <circle cx={91.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={91.5} r={1.5} />
    <circle cx={163.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={505.5} r={1.5} />
    <circle cx={91.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={91.5} r={1.5} />
    <circle cx={154.5} cy={568.5} r={1.5} />
    <circle cx={595.5} cy={496.5} r={1.5} />
    <circle cx={64.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={64.5} r={1.5} />
    <circle cx={163.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={505.5} r={1.5} />
    <circle cx={64.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={64.5} r={1.5} />
    <circle cx={154.5} cy={595.5} r={1.5} />
    <circle cx={541.5} cy={496.5} r={1.5} />
    <circle cx={118.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={118.5} r={1.5} />
    <circle cx={163.5} cy={541.5} r={1.5} />
    <circle cx={541.5} cy={505.5} r={1.5} />
    <circle cx={118.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={118.5} r={1.5} />
    <circle cx={154.5} cy={541.5} r={1.5} />
    <circle cx={613.5} cy={496.5} r={1.5} />
    <circle cx={46.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={46.5} r={1.5} />
    <circle cx={163.5} cy={613.5} r={1.5} />
    <circle cx={613.5} cy={505.5} r={1.5} />
    <circle cx={46.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={46.5} r={1.5} />
    <circle cx={154.5} cy={613.5} r={1.5} />
    <circle cx={559.5} cy={496.5} r={1.5} />
    <circle cx={100.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={100.5} r={1.5} />
    <circle cx={163.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={505.5} r={1.5} />
    <circle cx={100.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={100.5} r={1.5} />
    <circle cx={154.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={424.5} r={1.5} />
    <circle cx={100.5} cy={235.5} r={1.5} />
    <circle cx={424.5} cy={100.5} r={1.5} />
    <circle cx={235.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={433.5} r={1.5} />
    <circle cx={100.5} cy={226.5} r={1.5} />
    <circle cx={433.5} cy={100.5} r={1.5} />
    <circle cx={226.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={442.5} r={1.5} />
    <circle cx={100.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={100.5} r={1.5} />
    <circle cx={217.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={451.5} r={1.5} />
    <circle cx={100.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={100.5} r={1.5} />
    <circle cx={208.5} cy={559.5} r={1.5} />
    <circle cx={586.5} cy={496.5} r={1.5} />
    <circle cx={73.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={73.5} r={1.5} />
    <circle cx={163.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={505.5} r={1.5} />
    <circle cx={73.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={73.5} r={1.5} />
    <circle cx={154.5} cy={586.5} r={1.5} />
    <circle cx={604.5} cy={496.5} r={1.5} />
    <circle cx={55.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={55.5} r={1.5} />
    <circle cx={163.5} cy={604.5} r={1.5} />
    <circle cx={604.5} cy={505.5} r={1.5} />
    <circle cx={55.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={55.5} r={1.5} />
    <circle cx={154.5} cy={604.5} r={1.5} />
    <circle cx={550.5} cy={496.5} r={1.5} />
    <circle cx={109.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={109.5} r={1.5} />
    <circle cx={163.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={505.5} r={1.5} />
    <circle cx={109.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={109.5} r={1.5} />
    <circle cx={154.5} cy={550.5} r={1.5} />
    <circle cx={577.5} cy={496.5} r={1.5} />
    <circle cx={82.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={82.5} r={1.5} />
    <circle cx={163.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={505.5} r={1.5} />
    <circle cx={82.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={82.5} r={1.5} />
    <circle cx={154.5} cy={577.5} r={1.5} />
    <circle cx={604.5} cy={514.5} r={1.5} />
    <circle cx={55.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={55.5} r={1.5} />
    <circle cx={145.5} cy={604.5} r={1.5} />
    <circle cx={550.5} cy={514.5} r={1.5} />
    <circle cx={109.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={109.5} r={1.5} />
    <circle cx={145.5} cy={550.5} r={1.5} />
    <circle cx={577.5} cy={514.5} r={1.5} />
    <circle cx={82.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={82.5} r={1.5} />
    <circle cx={145.5} cy={577.5} r={1.5} />
    <circle cx={523.5} cy={514.5} r={1.5} />
    <circle cx={136.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={136.5} r={1.5} />
    <circle cx={145.5} cy={523.5} r={1.5} />
    <circle cx={595.5} cy={514.5} r={1.5} />
    <circle cx={64.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={64.5} r={1.5} />
    <circle cx={145.5} cy={595.5} r={1.5} />
    <circle cx={541.5} cy={514.5} r={1.5} />
    <circle cx={118.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={118.5} r={1.5} />
    <circle cx={145.5} cy={541.5} r={1.5} />
    <circle cx={568.5} cy={514.5} r={1.5} />
    <circle cx={91.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={91.5} r={1.5} />
    <circle cx={145.5} cy={568.5} r={1.5} />
    <circle cx={586.5} cy={514.5} r={1.5} />
    <circle cx={73.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={73.5} r={1.5} />
    <circle cx={145.5} cy={586.5} r={1.5} />
    <circle cx={532.5} cy={514.5} r={1.5} />
    <circle cx={127.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={127.5} r={1.5} />
    <circle cx={145.5} cy={532.5} r={1.5} />
    <circle cx={559.5} cy={514.5} r={1.5} />
    <circle cx={100.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={100.5} r={1.5} />
    <circle cx={145.5} cy={559.5} r={1.5} />
    <circle cx={604.5} cy={523.5} r={1.5} />
    <circle cx={55.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={55.5} r={1.5} />
    <circle cx={136.5} cy={604.5} r={1.5} />
    <circle cx={550.5} cy={523.5} r={1.5} />
    <circle cx={109.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={109.5} r={1.5} />
    <circle cx={136.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={532.5} r={1.5} />
    <circle cx={109.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={109.5} r={1.5} />
    <circle cx={127.5} cy={550.5} r={1.5} />
    <circle cx={577.5} cy={523.5} r={1.5} />
    <circle cx={82.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={82.5} r={1.5} />
    <circle cx={136.5} cy={577.5} r={1.5} />
    <circle cx={577.5} cy={532.5} r={1.5} />
    <circle cx={82.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={82.5} r={1.5} />
    <circle cx={127.5} cy={577.5} r={1.5} />
    <circle cx={523.5} cy={523.5} r={1.5} />
    <circle cx={136.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={136.5} r={1.5} />
    <circle cx={136.5} cy={523.5} r={1.5} />
    <circle cx={523.5} cy={532.5} r={1.5} />
    <circle cx={136.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={136.5} r={1.5} />
    <circle cx={127.5} cy={523.5} r={1.5} />
    <circle cx={595.5} cy={523.5} r={1.5} />
    <circle cx={64.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={64.5} r={1.5} />
    <circle cx={136.5} cy={595.5} r={1.5} />
    <circle cx={595.5} cy={532.5} r={1.5} />
    <circle cx={64.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={64.5} r={1.5} />
    <circle cx={127.5} cy={595.5} r={1.5} />
    <circle cx={541.5} cy={523.5} r={1.5} />
    <circle cx={118.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={118.5} r={1.5} />
    <circle cx={136.5} cy={541.5} r={1.5} />
    <circle cx={541.5} cy={532.5} r={1.5} />
    <circle cx={118.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={118.5} r={1.5} />
    <circle cx={127.5} cy={541.5} r={1.5} />
    <circle cx={568.5} cy={523.5} r={1.5} />
    <circle cx={91.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={91.5} r={1.5} />
    <circle cx={136.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={532.5} r={1.5} />
    <circle cx={91.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={91.5} r={1.5} />
    <circle cx={127.5} cy={568.5} r={1.5} />
    <circle cx={586.5} cy={523.5} r={1.5} />
    <circle cx={73.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={73.5} r={1.5} />
    <circle cx={136.5} cy={586.5} r={1.5} />
    <circle cx={586.5} cy={532.5} r={1.5} />
    <circle cx={73.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={73.5} r={1.5} />
    <circle cx={127.5} cy={586.5} r={1.5} />
    <circle cx={532.5} cy={523.5} r={1.5} />
    <circle cx={127.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={127.5} r={1.5} />
    <circle cx={136.5} cy={532.5} r={1.5} />
    <circle cx={532.5} cy={532.5} r={1.5} />
    <circle cx={127.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={127.5} r={1.5} />
    <circle cx={127.5} cy={532.5} r={1.5} />
    <circle cx={559.5} cy={523.5} r={1.5} />
    <circle cx={100.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={100.5} r={1.5} />
    <circle cx={136.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={532.5} r={1.5} />
    <circle cx={100.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={100.5} r={1.5} />
    <circle cx={127.5} cy={559.5} r={1.5} />
    <circle cx={586.5} cy={541.5} r={1.5} />
    <circle cx={73.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={73.5} r={1.5} />
    <circle cx={118.5} cy={586.5} r={1.5} />
    <circle cx={532.5} cy={541.5} r={1.5} />
    <circle cx={127.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={127.5} r={1.5} />
    <circle cx={118.5} cy={532.5} r={1.5} />
    <circle cx={559.5} cy={541.5} r={1.5} />
    <circle cx={100.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={100.5} r={1.5} />
    <circle cx={118.5} cy={559.5} r={1.5} />
    <circle cx={505.5} cy={541.5} r={1.5} />
    <circle cx={154.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={154.5} r={1.5} />
    <circle cx={118.5} cy={505.5} r={1.5} />
    <circle cx={577.5} cy={541.5} r={1.5} />
    <circle cx={82.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={82.5} r={1.5} />
    <circle cx={118.5} cy={577.5} r={1.5} />
    <circle cx={523.5} cy={541.5} r={1.5} />
    <circle cx={136.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={136.5} r={1.5} />
    <circle cx={118.5} cy={523.5} r={1.5} />
    <circle cx={550.5} cy={541.5} r={1.5} />
    <circle cx={109.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={109.5} r={1.5} />
    <circle cx={118.5} cy={550.5} r={1.5} />
    <circle cx={568.5} cy={541.5} r={1.5} />
    <circle cx={91.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={91.5} r={1.5} />
    <circle cx={118.5} cy={568.5} r={1.5} />
    <circle cx={514.5} cy={541.5} r={1.5} />
    <circle cx={145.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={145.5} r={1.5} />
    <circle cx={118.5} cy={514.5} r={1.5} />
    <circle cx={541.5} cy={541.5} r={1.5} />
    <circle cx={118.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={118.5} r={1.5} />
    <circle cx={118.5} cy={541.5} r={1.5} />
    <circle cx={532.5} cy={550.5} r={1.5} />
    <circle cx={127.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={127.5} r={1.5} />
    <circle cx={109.5} cy={532.5} r={1.5} />
    <circle cx={532.5} cy={559.5} r={1.5} />
    <circle cx={127.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={127.5} r={1.5} />
    <circle cx={100.5} cy={532.5} r={1.5} />
    <circle cx={559.5} cy={550.5} r={1.5} />
    <circle cx={100.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={100.5} r={1.5} />
    <circle cx={109.5} cy={559.5} r={1.5} />
    <circle cx={559.5} cy={559.5} r={1.5} />
    <circle cx={100.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={100.5} r={1.5} />
    <circle cx={100.5} cy={559.5} r={1.5} />
    <circle cx={505.5} cy={550.5} r={1.5} />
    <circle cx={154.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={154.5} r={1.5} />
    <circle cx={109.5} cy={505.5} r={1.5} />
    <circle cx={505.5} cy={559.5} r={1.5} />
    <circle cx={154.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={154.5} r={1.5} />
    <circle cx={100.5} cy={505.5} r={1.5} />
    <circle cx={577.5} cy={550.5} r={1.5} />
    <circle cx={82.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={82.5} r={1.5} />
    <circle cx={109.5} cy={577.5} r={1.5} />
    <circle cx={523.5} cy={550.5} r={1.5} />
    <circle cx={136.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={136.5} r={1.5} />
    <circle cx={109.5} cy={523.5} r={1.5} />
    <circle cx={523.5} cy={559.5} r={1.5} />
    <circle cx={136.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={136.5} r={1.5} />
    <circle cx={100.5} cy={523.5} r={1.5} />
    <circle cx={550.5} cy={550.5} r={1.5} />
    <circle cx={109.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={109.5} r={1.5} />
    <circle cx={109.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={559.5} r={1.5} />
    <circle cx={109.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={109.5} r={1.5} />
    <circle cx={100.5} cy={550.5} r={1.5} />
    <circle cx={568.5} cy={550.5} r={1.5} />
    <circle cx={91.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={91.5} r={1.5} />
    <circle cx={109.5} cy={568.5} r={1.5} />
    <circle cx={568.5} cy={559.5} r={1.5} />
    <circle cx={91.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={91.5} r={1.5} />
    <circle cx={100.5} cy={568.5} r={1.5} />
    <circle cx={514.5} cy={550.5} r={1.5} />
    <circle cx={145.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={145.5} r={1.5} />
    <circle cx={109.5} cy={514.5} r={1.5} />
    <circle cx={514.5} cy={559.5} r={1.5} />
    <circle cx={145.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={145.5} r={1.5} />
    <circle cx={100.5} cy={514.5} r={1.5} />
    <circle cx={541.5} cy={550.5} r={1.5} />
    <circle cx={118.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={118.5} r={1.5} />
    <circle cx={109.5} cy={541.5} r={1.5} />
    <circle cx={541.5} cy={559.5} r={1.5} />
    <circle cx={118.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={118.5} r={1.5} />
    <circle cx={100.5} cy={541.5} r={1.5} />
    <circle cx={559.5} cy={568.5} r={1.5} />
    <circle cx={100.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={100.5} r={1.5} />
    <circle cx={91.5} cy={559.5} r={1.5} />
    <circle cx={505.5} cy={568.5} r={1.5} />
    <circle cx={154.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={154.5} r={1.5} />
    <circle cx={91.5} cy={505.5} r={1.5} />
    <circle cx={532.5} cy={568.5} r={1.5} />
    <circle cx={127.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={127.5} r={1.5} />
    <circle cx={91.5} cy={532.5} r={1.5} />
    <circle cx={478.5} cy={568.5} r={1.5} />
    <circle cx={181.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={181.5} r={1.5} />
    <circle cx={91.5} cy={478.5} r={1.5} />
    <circle cx={550.5} cy={568.5} r={1.5} />
    <circle cx={109.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={109.5} r={1.5} />
    <circle cx={91.5} cy={550.5} r={1.5} />
    <circle cx={496.5} cy={568.5} r={1.5} />
    <circle cx={163.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={163.5} r={1.5} />
    <circle cx={91.5} cy={496.5} r={1.5} />
    <circle cx={523.5} cy={568.5} r={1.5} />
    <circle cx={136.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={136.5} r={1.5} />
    <circle cx={91.5} cy={523.5} r={1.5} />
    <circle cx={541.5} cy={568.5} r={1.5} />
    <circle cx={118.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={118.5} r={1.5} />
    <circle cx={91.5} cy={541.5} r={1.5} />
    <circle cx={487.5} cy={568.5} r={1.5} />
    <circle cx={172.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={172.5} r={1.5} />
    <circle cx={91.5} cy={487.5} r={1.5} />
    <circle cx={514.5} cy={568.5} r={1.5} />
    <circle cx={145.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={145.5} r={1.5} />
    <circle cx={91.5} cy={514.5} r={1.5} />
    <circle cx={505.5} cy={577.5} r={1.5} />
    <circle cx={154.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={154.5} r={1.5} />
    <circle cx={82.5} cy={505.5} r={1.5} />
    <circle cx={505.5} cy={586.5} r={1.5} />
    <circle cx={154.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={154.5} r={1.5} />
    <circle cx={73.5} cy={505.5} r={1.5} />
    <circle cx={532.5} cy={577.5} r={1.5} />
    <circle cx={127.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={127.5} r={1.5} />
    <circle cx={82.5} cy={532.5} r={1.5} />
    <circle cx={532.5} cy={586.5} r={1.5} />
    <circle cx={127.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={127.5} r={1.5} />
    <circle cx={73.5} cy={532.5} r={1.5} />
    <circle cx={478.5} cy={577.5} r={1.5} />
    <circle cx={181.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={181.5} r={1.5} />
    <circle cx={82.5} cy={478.5} r={1.5} />
    <circle cx={478.5} cy={586.5} r={1.5} />
    <circle cx={181.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={181.5} r={1.5} />
    <circle cx={73.5} cy={478.5} r={1.5} />
    <circle cx={550.5} cy={577.5} r={1.5} />
    <circle cx={109.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={109.5} r={1.5} />
    <circle cx={82.5} cy={550.5} r={1.5} />
    <circle cx={496.5} cy={577.5} r={1.5} />
    <circle cx={163.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={163.5} r={1.5} />
    <circle cx={82.5} cy={496.5} r={1.5} />
    <circle cx={496.5} cy={586.5} r={1.5} />
    <circle cx={163.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={163.5} r={1.5} />
    <circle cx={73.5} cy={496.5} r={1.5} />
    <circle cx={523.5} cy={577.5} r={1.5} />
    <circle cx={136.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={136.5} r={1.5} />
    <circle cx={82.5} cy={523.5} r={1.5} />
    <circle cx={523.5} cy={586.5} r={1.5} />
    <circle cx={136.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={136.5} r={1.5} />
    <circle cx={73.5} cy={523.5} r={1.5} />
    <circle cx={541.5} cy={577.5} r={1.5} />
    <circle cx={118.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={118.5} r={1.5} />
    <circle cx={82.5} cy={541.5} r={1.5} />
    <circle cx={541.5} cy={586.5} r={1.5} />
    <circle cx={118.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={118.5} r={1.5} />
    <circle cx={73.5} cy={541.5} r={1.5} />
    <circle cx={487.5} cy={577.5} r={1.5} />
    <circle cx={172.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={172.5} r={1.5} />
    <circle cx={82.5} cy={487.5} r={1.5} />
    <circle cx={487.5} cy={586.5} r={1.5} />
    <circle cx={172.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={172.5} r={1.5} />
    <circle cx={73.5} cy={487.5} r={1.5} />
    <circle cx={514.5} cy={577.5} r={1.5} />
    <circle cx={145.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={145.5} r={1.5} />
    <circle cx={82.5} cy={514.5} r={1.5} />
    <circle cx={514.5} cy={586.5} r={1.5} />
    <circle cx={145.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={145.5} r={1.5} />
    <circle cx={73.5} cy={514.5} r={1.5} />
    <circle cx={532.5} cy={595.5} r={1.5} />
    <circle cx={127.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={127.5} r={1.5} />
    <circle cx={64.5} cy={532.5} r={1.5} />
    <circle cx={478.5} cy={595.5} r={1.5} />
    <circle cx={181.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={181.5} r={1.5} />
    <circle cx={64.5} cy={478.5} r={1.5} />
    <circle cx={505.5} cy={595.5} r={1.5} />
    <circle cx={154.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={154.5} r={1.5} />
    <circle cx={64.5} cy={505.5} r={1.5} />
    <circle cx={451.5} cy={595.5} r={1.5} />
    <circle cx={208.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={208.5} r={1.5} />
    <circle cx={64.5} cy={451.5} r={1.5} />
    <circle cx={523.5} cy={595.5} r={1.5} />
    <circle cx={136.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={136.5} r={1.5} />
    <circle cx={64.5} cy={523.5} r={1.5} />
    <circle cx={469.5} cy={595.5} r={1.5} />
    <circle cx={190.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={190.5} r={1.5} />
    <circle cx={64.5} cy={469.5} r={1.5} />
    <circle cx={496.5} cy={595.5} r={1.5} />
    <circle cx={163.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={163.5} r={1.5} />
    <circle cx={64.5} cy={496.5} r={1.5} />
    <circle cx={514.5} cy={595.5} r={1.5} />
    <circle cx={145.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={145.5} r={1.5} />
    <circle cx={64.5} cy={514.5} r={1.5} />
    <circle cx={460.5} cy={595.5} r={1.5} />
    <circle cx={199.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={199.5} r={1.5} />
    <circle cx={64.5} cy={460.5} r={1.5} />
    <circle cx={487.5} cy={595.5} r={1.5} />
    <circle cx={172.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={172.5} r={1.5} />
    <circle cx={64.5} cy={487.5} r={1.5} />
    <circle cx={478.5} cy={604.5} r={1.5} />
    <circle cx={181.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={181.5} r={1.5} />
    <circle cx={55.5} cy={478.5} r={1.5} />
    <circle cx={478.5} cy={613.5} r={1.5} />
    <circle cx={181.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={181.5} r={1.5} />
    <circle cx={46.5} cy={478.5} r={1.5} />
    <circle cx={505.5} cy={604.5} r={1.5} />
    <circle cx={154.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={154.5} r={1.5} />
    <circle cx={55.5} cy={505.5} r={1.5} />
    <circle cx={505.5} cy={613.5} r={1.5} />
    <circle cx={154.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={154.5} r={1.5} />
    <circle cx={46.5} cy={505.5} r={1.5} />
    <circle cx={451.5} cy={604.5} r={1.5} />
    <circle cx={208.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={208.5} r={1.5} />
    <circle cx={55.5} cy={451.5} r={1.5} />
    <circle cx={451.5} cy={613.5} r={1.5} />
    <circle cx={208.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={208.5} r={1.5} />
    <circle cx={46.5} cy={451.5} r={1.5} />
    <circle cx={523.5} cy={604.5} r={1.5} />
    <circle cx={136.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={136.5} r={1.5} />
    <circle cx={55.5} cy={523.5} r={1.5} />
    <circle cx={469.5} cy={604.5} r={1.5} />
    <circle cx={190.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={190.5} r={1.5} />
    <circle cx={55.5} cy={469.5} r={1.5} />
    <circle cx={469.5} cy={613.5} r={1.5} />
    <circle cx={190.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={190.5} r={1.5} />
    <circle cx={46.5} cy={469.5} r={1.5} />
    <circle cx={496.5} cy={604.5} r={1.5} />
    <circle cx={163.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={163.5} r={1.5} />
    <circle cx={55.5} cy={496.5} r={1.5} />
    <circle cx={496.5} cy={613.5} r={1.5} />
    <circle cx={163.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={163.5} r={1.5} />
    <circle cx={46.5} cy={496.5} r={1.5} />
    <circle cx={514.5} cy={604.5} r={1.5} />
    <circle cx={145.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={145.5} r={1.5} />
    <circle cx={55.5} cy={514.5} r={1.5} />
    <circle cx={460.5} cy={604.5} r={1.5} />
    <circle cx={199.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={199.5} r={1.5} />
    <circle cx={55.5} cy={460.5} r={1.5} />
    <circle cx={460.5} cy={613.5} r={1.5} />
    <circle cx={199.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={199.5} r={1.5} />
    <circle cx={46.5} cy={460.5} r={1.5} />
    <circle cx={487.5} cy={604.5} r={1.5} />
    <circle cx={172.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={172.5} r={1.5} />
    <circle cx={55.5} cy={487.5} r={1.5} />
    <circle cx={487.5} cy={613.5} r={1.5} />
    <circle cx={172.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={172.5} r={1.5} />
    <circle cx={46.5} cy={487.5} r={1.5} />
    <circle cx={487.5} cy={622.5} r={1.5} />
    <circle cx={172.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={172.5} r={1.5} />
    <circle cx={37.5} cy={487.5} r={1.5} />
    <circle cx={433.5} cy={622.5} r={1.5} />
    <circle cx={226.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={226.5} r={1.5} />
    <circle cx={37.5} cy={433.5} r={1.5} />
    <circle cx={460.5} cy={622.5} r={1.5} />
    <circle cx={199.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={199.5} r={1.5} />
    <circle cx={37.5} cy={460.5} r={1.5} />
    <circle cx={406.5} cy={622.5} r={1.5} />
    <circle cx={253.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={253.5} r={1.5} />
    <circle cx={37.5} cy={406.5} r={1.5} />
    <circle cx={478.5} cy={622.5} r={1.5} />
    <circle cx={181.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={181.5} r={1.5} />
    <circle cx={37.5} cy={478.5} r={1.5} />
    <circle cx={424.5} cy={622.5} r={1.5} />
    <circle cx={235.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={235.5} r={1.5} />
    <circle cx={37.5} cy={424.5} r={1.5} />
    <circle cx={451.5} cy={622.5} r={1.5} />
    <circle cx={208.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={208.5} r={1.5} />
    <circle cx={37.5} cy={451.5} r={1.5} />
    <circle cx={469.5} cy={622.5} r={1.5} />
    <circle cx={190.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={190.5} r={1.5} />
    <circle cx={37.5} cy={469.5} r={1.5} />
    <circle cx={415.5} cy={622.5} r={1.5} />
    <circle cx={244.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={244.5} r={1.5} />
    <circle cx={37.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={622.5} r={1.5} />
    <circle cx={217.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={217.5} r={1.5} />
    <circle cx={37.5} cy={442.5} r={1.5} />
    <circle cx={433.5} cy={631.5} r={1.5} />
    <circle cx={226.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={226.5} r={1.5} />
    <circle cx={28.5} cy={433.5} r={1.5} />
    <circle cx={433.5} cy={640.5} r={1.5} />
    <circle cx={226.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={226.5} r={1.5} />
    <circle cx={19.5} cy={433.5} r={1.5} />
    <circle cx={460.5} cy={631.5} r={1.5} />
    <circle cx={199.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={199.5} r={1.5} />
    <circle cx={28.5} cy={460.5} r={1.5} />
    <circle cx={406.5} cy={631.5} r={1.5} />
    <circle cx={253.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={253.5} r={1.5} />
    <circle cx={28.5} cy={406.5} r={1.5} />
    <circle cx={406.5} cy={640.5} r={1.5} />
    <circle cx={253.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={253.5} r={1.5} />
    <circle cx={19.5} cy={406.5} r={1.5} />
    <circle cx={478.5} cy={631.5} r={1.5} />
    <circle cx={181.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={181.5} r={1.5} />
    <circle cx={28.5} cy={478.5} r={1.5} />
    <circle cx={424.5} cy={631.5} r={1.5} />
    <circle cx={235.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={235.5} r={1.5} />
    <circle cx={28.5} cy={424.5} r={1.5} />
    <circle cx={424.5} cy={640.5} r={1.5} />
    <circle cx={235.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={235.5} r={1.5} />
    <circle cx={19.5} cy={424.5} r={1.5} />
    <circle cx={451.5} cy={631.5} r={1.5} />
    <circle cx={208.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={208.5} r={1.5} />
    <circle cx={28.5} cy={451.5} r={1.5} />
    <circle cx={451.5} cy={640.5} r={1.5} />
    <circle cx={208.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={208.5} r={1.5} />
    <circle cx={19.5} cy={451.5} r={1.5} />
    <circle cx={469.5} cy={631.5} r={1.5} />
    <circle cx={190.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={190.5} r={1.5} />
    <circle cx={28.5} cy={469.5} r={1.5} />
    <circle cx={415.5} cy={631.5} r={1.5} />
    <circle cx={244.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={244.5} r={1.5} />
    <circle cx={28.5} cy={415.5} r={1.5} />
    <circle cx={415.5} cy={640.5} r={1.5} />
    <circle cx={244.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={244.5} r={1.5} />
    <circle cx={19.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={631.5} r={1.5} />
    <circle cx={217.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={217.5} r={1.5} />
    <circle cx={28.5} cy={442.5} r={1.5} />
    <circle cx={442.5} cy={640.5} r={1.5} />
    <circle cx={217.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={217.5} r={1.5} />
    <circle cx={19.5} cy={442.5} r={1.5} />
    <circle cx={397.5} cy={622.5} r={1.5} />
    <circle cx={262.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={262.5} r={1.5} />
    <circle cx={37.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={622.5} r={1.5} />
    <circle cx={316.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={316.5} r={1.5} />
    <circle cx={37.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={622.5} r={1.5} />
    <circle cx={289.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={289.5} r={1.5} />
    <circle cx={37.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={622.5} r={1.5} />
    <circle cx={271.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={271.5} r={1.5} />
    <circle cx={37.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={622.5} r={1.5} />
    <circle cx={325.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={325.5} r={1.5} />
    <circle cx={37.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={622.5} r={1.5} />
    <circle cx={298.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={298.5} r={1.5} />
    <circle cx={37.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={622.5} r={1.5} />
    <circle cx={280.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={280.5} r={1.5} />
    <circle cx={37.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={622.5} r={1.5} />
    <circle cx={307.5} cy={37.5} r={1.5} />
    <circle cx={622.5} cy={307.5} r={1.5} />
    <circle cx={37.5} cy={352.5} r={1.5} />
    <circle cx={397.5} cy={631.5} r={1.5} />
    <circle cx={262.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={262.5} r={1.5} />
    <circle cx={28.5} cy={397.5} r={1.5} />
    <circle cx={397.5} cy={640.5} r={1.5} />
    <circle cx={262.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={262.5} r={1.5} />
    <circle cx={19.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={631.5} r={1.5} />
    <circle cx={316.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={316.5} r={1.5} />
    <circle cx={28.5} cy={343.5} r={1.5} />
    <circle cx={343.5} cy={640.5} r={1.5} />
    <circle cx={316.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={316.5} r={1.5} />
    <circle cx={19.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={631.5} r={1.5} />
    <circle cx={289.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={289.5} r={1.5} />
    <circle cx={28.5} cy={370.5} r={1.5} />
    <circle cx={370.5} cy={640.5} r={1.5} />
    <circle cx={289.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={289.5} r={1.5} />
    <circle cx={19.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={631.5} r={1.5} />
    <circle cx={271.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={271.5} r={1.5} />
    <circle cx={28.5} cy={388.5} r={1.5} />
    <circle cx={388.5} cy={640.5} r={1.5} />
    <circle cx={271.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={271.5} r={1.5} />
    <circle cx={19.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={631.5} r={1.5} />
    <circle cx={325.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={325.5} r={1.5} />
    <circle cx={28.5} cy={334.5} r={1.5} />
    <circle cx={334.5} cy={640.5} r={1.5} />
    <circle cx={325.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={325.5} r={1.5} />
    <circle cx={19.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={631.5} r={1.5} />
    <circle cx={298.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={298.5} r={1.5} />
    <circle cx={28.5} cy={361.5} r={1.5} />
    <circle cx={361.5} cy={640.5} r={1.5} />
    <circle cx={298.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={298.5} r={1.5} />
    <circle cx={19.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={631.5} r={1.5} />
    <circle cx={280.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={280.5} r={1.5} />
    <circle cx={28.5} cy={379.5} r={1.5} />
    <circle cx={379.5} cy={640.5} r={1.5} />
    <circle cx={280.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={280.5} r={1.5} />
    <circle cx={19.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={631.5} r={1.5} />
    <circle cx={307.5} cy={28.5} r={1.5} />
    <circle cx={631.5} cy={307.5} r={1.5} />
    <circle cx={28.5} cy={352.5} r={1.5} />
    <circle cx={352.5} cy={640.5} r={1.5} />
    <circle cx={307.5} cy={19.5} r={1.5} />
    <circle cx={640.5} cy={307.5} r={1.5} />
    <circle cx={19.5} cy={352.5} r={1.5} />
    <circle cx={406.5} cy={649.5} r={1.5} />
    <circle cx={253.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={253.5} r={1.5} />
    <circle cx={10.5} cy={406.5} r={1.5} />
    <circle cx={424.5} cy={649.5} r={1.5} />
    <circle cx={235.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={235.5} r={1.5} />
    <circle cx={10.5} cy={424.5} r={1.5} />
    <circle cx={415.5} cy={649.5} r={1.5} />
    <circle cx={244.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={244.5} r={1.5} />
    <circle cx={10.5} cy={415.5} r={1.5} />
    <circle cx={397.5} cy={649.5} r={1.5} />
    <circle cx={262.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={262.5} r={1.5} />
    <circle cx={10.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={649.5} r={1.5} />
    <circle cx={316.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={316.5} r={1.5} />
    <circle cx={10.5} cy={343.5} r={1.5} />
    <circle cx={343.5} cy={658.5} r={1.5} />
    <circle cx={316.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={316.5} r={1.5} />
    <circle cx={1.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={649.5} r={1.5} />
    <circle cx={289.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={289.5} r={1.5} />
    <circle cx={10.5} cy={370.5} r={1.5} />
    <circle cx={370.5} cy={658.5} r={1.5} />
    <circle cx={289.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={289.5} r={1.5} />
    <circle cx={1.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={649.5} r={1.5} />
    <circle cx={271.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={271.5} r={1.5} />
    <circle cx={10.5} cy={388.5} r={1.5} />
    <circle cx={388.5} cy={658.5} r={1.5} />
    <circle cx={271.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={271.5} r={1.5} />
    <circle cx={1.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={649.5} r={1.5} />
    <circle cx={325.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={325.5} r={1.5} />
    <circle cx={10.5} cy={334.5} r={1.5} />
    <circle cx={334.5} cy={658.5} r={1.5} />
    <circle cx={325.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={325.5} r={1.5} />
    <circle cx={1.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={649.5} r={1.5} />
    <circle cx={298.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={298.5} r={1.5} />
    <circle cx={10.5} cy={361.5} r={1.5} />
    <circle cx={361.5} cy={658.5} r={1.5} />
    <circle cx={298.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={298.5} r={1.5} />
    <circle cx={1.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={649.5} r={1.5} />
    <circle cx={280.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={280.5} r={1.5} />
    <circle cx={10.5} cy={379.5} r={1.5} />
    <circle cx={379.5} cy={658.5} r={1.5} />
    <circle cx={280.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={280.5} r={1.5} />
    <circle cx={1.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={649.5} r={1.5} />
    <circle cx={307.5} cy={10.5} r={1.5} />
    <circle cx={649.5} cy={307.5} r={1.5} />
    <circle cx={10.5} cy={352.5} r={1.5} />
    <circle cx={352.5} cy={658.5} r={1.5} />
    <circle cx={307.5} cy={1.5} r={1.5} />
    <circle cx={658.5} cy={307.5} r={1.5} />
    <circle cx={1.5} cy={352.5} r={1.5} />
    <circle cx={433.5} cy={595.5} r={1.5} />
    <circle cx={226.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={226.5} r={1.5} />
    <circle cx={64.5} cy={433.5} r={1.5} />
    <circle cx={406.5} cy={595.5} r={1.5} />
    <circle cx={253.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={253.5} r={1.5} />
    <circle cx={64.5} cy={406.5} r={1.5} />
    <circle cx={424.5} cy={595.5} r={1.5} />
    <circle cx={235.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={235.5} r={1.5} />
    <circle cx={64.5} cy={424.5} r={1.5} />
    <circle cx={415.5} cy={595.5} r={1.5} />
    <circle cx={244.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={244.5} r={1.5} />
    <circle cx={64.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={595.5} r={1.5} />
    <circle cx={217.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={217.5} r={1.5} />
    <circle cx={64.5} cy={442.5} r={1.5} />
    <circle cx={433.5} cy={604.5} r={1.5} />
    <circle cx={226.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={226.5} r={1.5} />
    <circle cx={55.5} cy={433.5} r={1.5} />
    <circle cx={433.5} cy={613.5} r={1.5} />
    <circle cx={226.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={226.5} r={1.5} />
    <circle cx={46.5} cy={433.5} r={1.5} />
    <circle cx={406.5} cy={604.5} r={1.5} />
    <circle cx={253.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={253.5} r={1.5} />
    <circle cx={55.5} cy={406.5} r={1.5} />
    <circle cx={406.5} cy={613.5} r={1.5} />
    <circle cx={253.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={253.5} r={1.5} />
    <circle cx={46.5} cy={406.5} r={1.5} />
    <circle cx={424.5} cy={604.5} r={1.5} />
    <circle cx={235.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={235.5} r={1.5} />
    <circle cx={55.5} cy={424.5} r={1.5} />
    <circle cx={424.5} cy={613.5} r={1.5} />
    <circle cx={235.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={235.5} r={1.5} />
    <circle cx={46.5} cy={424.5} r={1.5} />
    <circle cx={415.5} cy={604.5} r={1.5} />
    <circle cx={244.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={244.5} r={1.5} />
    <circle cx={55.5} cy={415.5} r={1.5} />
    <circle cx={415.5} cy={613.5} r={1.5} />
    <circle cx={244.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={244.5} r={1.5} />
    <circle cx={46.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={604.5} r={1.5} />
    <circle cx={217.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={217.5} r={1.5} />
    <circle cx={55.5} cy={442.5} r={1.5} />
    <circle cx={442.5} cy={613.5} r={1.5} />
    <circle cx={217.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={217.5} r={1.5} />
    <circle cx={46.5} cy={442.5} r={1.5} />
    <circle cx={397.5} cy={595.5} r={1.5} />
    <circle cx={262.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={262.5} r={1.5} />
    <circle cx={64.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={595.5} r={1.5} />
    <circle cx={316.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={316.5} r={1.5} />
    <circle cx={64.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={595.5} r={1.5} />
    <circle cx={289.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={289.5} r={1.5} />
    <circle cx={64.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={595.5} r={1.5} />
    <circle cx={271.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={271.5} r={1.5} />
    <circle cx={64.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={595.5} r={1.5} />
    <circle cx={325.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={325.5} r={1.5} />
    <circle cx={64.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={595.5} r={1.5} />
    <circle cx={298.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={298.5} r={1.5} />
    <circle cx={64.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={595.5} r={1.5} />
    <circle cx={280.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={280.5} r={1.5} />
    <circle cx={64.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={595.5} r={1.5} />
    <circle cx={307.5} cy={64.5} r={1.5} />
    <circle cx={595.5} cy={307.5} r={1.5} />
    <circle cx={64.5} cy={352.5} r={1.5} />
    <circle cx={397.5} cy={604.5} r={1.5} />
    <circle cx={262.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={262.5} r={1.5} />
    <circle cx={55.5} cy={397.5} r={1.5} />
    <circle cx={397.5} cy={613.5} r={1.5} />
    <circle cx={262.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={262.5} r={1.5} />
    <circle cx={46.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={604.5} r={1.5} />
    <circle cx={316.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={316.5} r={1.5} />
    <circle cx={55.5} cy={343.5} r={1.5} />
    <circle cx={343.5} cy={613.5} r={1.5} />
    <circle cx={316.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={316.5} r={1.5} />
    <circle cx={46.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={604.5} r={1.5} />
    <circle cx={289.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={289.5} r={1.5} />
    <circle cx={55.5} cy={370.5} r={1.5} />
    <circle cx={370.5} cy={613.5} r={1.5} />
    <circle cx={289.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={289.5} r={1.5} />
    <circle cx={46.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={604.5} r={1.5} />
    <circle cx={271.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={271.5} r={1.5} />
    <circle cx={55.5} cy={388.5} r={1.5} />
    <circle cx={388.5} cy={613.5} r={1.5} />
    <circle cx={271.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={271.5} r={1.5} />
    <circle cx={46.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={604.5} r={1.5} />
    <circle cx={325.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={325.5} r={1.5} />
    <circle cx={55.5} cy={334.5} r={1.5} />
    <circle cx={334.5} cy={613.5} r={1.5} />
    <circle cx={325.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={325.5} r={1.5} />
    <circle cx={46.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={604.5} r={1.5} />
    <circle cx={298.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={298.5} r={1.5} />
    <circle cx={55.5} cy={361.5} r={1.5} />
    <circle cx={361.5} cy={613.5} r={1.5} />
    <circle cx={298.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={298.5} r={1.5} />
    <circle cx={46.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={604.5} r={1.5} />
    <circle cx={280.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={280.5} r={1.5} />
    <circle cx={55.5} cy={379.5} r={1.5} />
    <circle cx={379.5} cy={613.5} r={1.5} />
    <circle cx={280.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={280.5} r={1.5} />
    <circle cx={46.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={604.5} r={1.5} />
    <circle cx={307.5} cy={55.5} r={1.5} />
    <circle cx={604.5} cy={307.5} r={1.5} />
    <circle cx={55.5} cy={352.5} r={1.5} />
    <circle cx={352.5} cy={613.5} r={1.5} />
    <circle cx={307.5} cy={46.5} r={1.5} />
    <circle cx={613.5} cy={307.5} r={1.5} />
    <circle cx={46.5} cy={352.5} r={1.5} />
    <circle cx={433.5} cy={568.5} r={1.5} />
    <circle cx={226.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={226.5} r={1.5} />
    <circle cx={91.5} cy={433.5} r={1.5} />
    <circle cx={406.5} cy={568.5} r={1.5} />
    <circle cx={253.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={253.5} r={1.5} />
    <circle cx={91.5} cy={406.5} r={1.5} />
    <circle cx={424.5} cy={568.5} r={1.5} />
    <circle cx={235.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={235.5} r={1.5} />
    <circle cx={91.5} cy={424.5} r={1.5} />
    <circle cx={415.5} cy={568.5} r={1.5} />
    <circle cx={244.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={244.5} r={1.5} />
    <circle cx={91.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={568.5} r={1.5} />
    <circle cx={217.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={217.5} r={1.5} />
    <circle cx={91.5} cy={442.5} r={1.5} />
    <circle cx={433.5} cy={577.5} r={1.5} />
    <circle cx={226.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={226.5} r={1.5} />
    <circle cx={82.5} cy={433.5} r={1.5} />
    <circle cx={433.5} cy={586.5} r={1.5} />
    <circle cx={226.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={226.5} r={1.5} />
    <circle cx={73.5} cy={433.5} r={1.5} />
    <circle cx={406.5} cy={577.5} r={1.5} />
    <circle cx={253.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={253.5} r={1.5} />
    <circle cx={82.5} cy={406.5} r={1.5} />
    <circle cx={406.5} cy={586.5} r={1.5} />
    <circle cx={253.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={253.5} r={1.5} />
    <circle cx={73.5} cy={406.5} r={1.5} />
    <circle cx={424.5} cy={577.5} r={1.5} />
    <circle cx={235.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={235.5} r={1.5} />
    <circle cx={82.5} cy={424.5} r={1.5} />
    <circle cx={424.5} cy={586.5} r={1.5} />
    <circle cx={235.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={235.5} r={1.5} />
    <circle cx={73.5} cy={424.5} r={1.5} />
    <circle cx={415.5} cy={577.5} r={1.5} />
    <circle cx={244.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={244.5} r={1.5} />
    <circle cx={82.5} cy={415.5} r={1.5} />
    <circle cx={415.5} cy={586.5} r={1.5} />
    <circle cx={244.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={244.5} r={1.5} />
    <circle cx={73.5} cy={415.5} r={1.5} />
    <circle cx={442.5} cy={577.5} r={1.5} />
    <circle cx={217.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={217.5} r={1.5} />
    <circle cx={82.5} cy={442.5} r={1.5} />
    <circle cx={442.5} cy={586.5} r={1.5} />
    <circle cx={217.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={217.5} r={1.5} />
    <circle cx={73.5} cy={442.5} r={1.5} />
    <circle cx={460.5} cy={568.5} r={1.5} />
    <circle cx={199.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={199.5} r={1.5} />
    <circle cx={91.5} cy={460.5} r={1.5} />
    <circle cx={451.5} cy={568.5} r={1.5} />
    <circle cx={208.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={208.5} r={1.5} />
    <circle cx={91.5} cy={451.5} r={1.5} />
    <circle cx={469.5} cy={568.5} r={1.5} />
    <circle cx={190.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={190.5} r={1.5} />
    <circle cx={91.5} cy={469.5} r={1.5} />
    <circle cx={460.5} cy={577.5} r={1.5} />
    <circle cx={199.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={199.5} r={1.5} />
    <circle cx={82.5} cy={460.5} r={1.5} />
    <circle cx={460.5} cy={586.5} r={1.5} />
    <circle cx={199.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={199.5} r={1.5} />
    <circle cx={73.5} cy={460.5} r={1.5} />
    <circle cx={451.5} cy={577.5} r={1.5} />
    <circle cx={208.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={208.5} r={1.5} />
    <circle cx={82.5} cy={451.5} r={1.5} />
    <circle cx={451.5} cy={586.5} r={1.5} />
    <circle cx={208.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={208.5} r={1.5} />
    <circle cx={73.5} cy={451.5} r={1.5} />
    <circle cx={469.5} cy={577.5} r={1.5} />
    <circle cx={190.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={190.5} r={1.5} />
    <circle cx={82.5} cy={469.5} r={1.5} />
    <circle cx={469.5} cy={586.5} r={1.5} />
    <circle cx={190.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={190.5} r={1.5} />
    <circle cx={73.5} cy={469.5} r={1.5} />
    <circle cx={478.5} cy={541.5} r={1.5} />
    <circle cx={181.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={181.5} r={1.5} />
    <circle cx={118.5} cy={478.5} r={1.5} />
    <circle cx={496.5} cy={541.5} r={1.5} />
    <circle cx={163.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={163.5} r={1.5} />
    <circle cx={118.5} cy={496.5} r={1.5} />
    <circle cx={487.5} cy={541.5} r={1.5} />
    <circle cx={172.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={172.5} r={1.5} />
    <circle cx={118.5} cy={487.5} r={1.5} />
    <circle cx={478.5} cy={550.5} r={1.5} />
    <circle cx={181.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={181.5} r={1.5} />
    <circle cx={109.5} cy={478.5} r={1.5} />
    <circle cx={478.5} cy={559.5} r={1.5} />
    <circle cx={181.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={181.5} r={1.5} />
    <circle cx={100.5} cy={478.5} r={1.5} />
    <circle cx={496.5} cy={550.5} r={1.5} />
    <circle cx={163.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={163.5} r={1.5} />
    <circle cx={109.5} cy={496.5} r={1.5} />
    <circle cx={496.5} cy={559.5} r={1.5} />
    <circle cx={163.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={163.5} r={1.5} />
    <circle cx={100.5} cy={496.5} r={1.5} />
    <circle cx={487.5} cy={550.5} r={1.5} />
    <circle cx={172.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={172.5} r={1.5} />
    <circle cx={109.5} cy={487.5} r={1.5} />
    <circle cx={487.5} cy={559.5} r={1.5} />
    <circle cx={172.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={172.5} r={1.5} />
    <circle cx={100.5} cy={487.5} r={1.5} />
    <circle cx={433.5} cy={559.5} r={1.5} />
    <circle cx={226.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={226.5} r={1.5} />
    <circle cx={100.5} cy={433.5} r={1.5} />
    <circle cx={424.5} cy={559.5} r={1.5} />
    <circle cx={235.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={235.5} r={1.5} />
    <circle cx={100.5} cy={424.5} r={1.5} />
    <circle cx={442.5} cy={550.5} r={1.5} />
    <circle cx={217.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={217.5} r={1.5} />
    <circle cx={109.5} cy={442.5} r={1.5} />
    <circle cx={442.5} cy={559.5} r={1.5} />
    <circle cx={217.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={217.5} r={1.5} />
    <circle cx={100.5} cy={442.5} r={1.5} />
    <circle cx={460.5} cy={541.5} r={1.5} />
    <circle cx={199.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={199.5} r={1.5} />
    <circle cx={118.5} cy={460.5} r={1.5} />
    <circle cx={469.5} cy={541.5} r={1.5} />
    <circle cx={190.5} cy={118.5} r={1.5} />
    <circle cx={541.5} cy={190.5} r={1.5} />
    <circle cx={118.5} cy={469.5} r={1.5} />
    <circle cx={460.5} cy={550.5} r={1.5} />
    <circle cx={199.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={199.5} r={1.5} />
    <circle cx={109.5} cy={460.5} r={1.5} />
    <circle cx={460.5} cy={559.5} r={1.5} />
    <circle cx={199.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={199.5} r={1.5} />
    <circle cx={100.5} cy={460.5} r={1.5} />
    <circle cx={451.5} cy={550.5} r={1.5} />
    <circle cx={208.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={208.5} r={1.5} />
    <circle cx={109.5} cy={451.5} r={1.5} />
    <circle cx={451.5} cy={559.5} r={1.5} />
    <circle cx={208.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={208.5} r={1.5} />
    <circle cx={100.5} cy={451.5} r={1.5} />
    <circle cx={469.5} cy={550.5} r={1.5} />
    <circle cx={190.5} cy={109.5} r={1.5} />
    <circle cx={550.5} cy={190.5} r={1.5} />
    <circle cx={109.5} cy={469.5} r={1.5} />
    <circle cx={469.5} cy={559.5} r={1.5} />
    <circle cx={190.5} cy={100.5} r={1.5} />
    <circle cx={559.5} cy={190.5} r={1.5} />
    <circle cx={100.5} cy={469.5} r={1.5} />
    <circle cx={505.5} cy={514.5} r={1.5} />
    <circle cx={154.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={154.5} r={1.5} />
    <circle cx={145.5} cy={505.5} r={1.5} />
    <circle cx={514.5} cy={514.5} r={1.5} />
    <circle cx={145.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={145.5} r={1.5} />
    <circle cx={145.5} cy={514.5} r={1.5} />
    <circle cx={505.5} cy={523.5} r={1.5} />
    <circle cx={154.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={154.5} r={1.5} />
    <circle cx={136.5} cy={505.5} r={1.5} />
    <circle cx={505.5} cy={532.5} r={1.5} />
    <circle cx={154.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={154.5} r={1.5} />
    <circle cx={127.5} cy={505.5} r={1.5} />
    <circle cx={514.5} cy={523.5} r={1.5} />
    <circle cx={145.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={145.5} r={1.5} />
    <circle cx={136.5} cy={514.5} r={1.5} />
    <circle cx={514.5} cy={532.5} r={1.5} />
    <circle cx={145.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={145.5} r={1.5} />
    <circle cx={127.5} cy={514.5} r={1.5} />
    <circle cx={496.5} cy={514.5} r={1.5} />
    <circle cx={163.5} cy={145.5} r={1.5} />
    <circle cx={514.5} cy={163.5} r={1.5} />
    <circle cx={145.5} cy={496.5} r={1.5} />
    <circle cx={478.5} cy={532.5} r={1.5} />
    <circle cx={181.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={181.5} r={1.5} />
    <circle cx={127.5} cy={478.5} r={1.5} />
    <circle cx={496.5} cy={523.5} r={1.5} />
    <circle cx={163.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={163.5} r={1.5} />
    <circle cx={136.5} cy={496.5} r={1.5} />
    <circle cx={496.5} cy={532.5} r={1.5} />
    <circle cx={163.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={163.5} r={1.5} />
    <circle cx={127.5} cy={496.5} r={1.5} />
    <circle cx={487.5} cy={523.5} r={1.5} />
    <circle cx={172.5} cy={136.5} r={1.5} />
    <circle cx={523.5} cy={172.5} r={1.5} />
    <circle cx={136.5} cy={487.5} r={1.5} />
    <circle cx={487.5} cy={532.5} r={1.5} />
    <circle cx={172.5} cy={127.5} r={1.5} />
    <circle cx={532.5} cy={172.5} r={1.5} />
    <circle cx={127.5} cy={487.5} r={1.5} />
    <circle cx={523.5} cy={487.5} r={1.5} />
    <circle cx={136.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={136.5} r={1.5} />
    <circle cx={172.5} cy={523.5} r={1.5} />
    <circle cx={532.5} cy={487.5} r={1.5} />
    <circle cx={127.5} cy={172.5} r={1.5} />
    <circle cx={487.5} cy={127.5} r={1.5} />
    <circle cx={172.5} cy={532.5} r={1.5} />
    <circle cx={523.5} cy={496.5} r={1.5} />
    <circle cx={136.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={136.5} r={1.5} />
    <circle cx={163.5} cy={523.5} r={1.5} />
    <circle cx={523.5} cy={505.5} r={1.5} />
    <circle cx={136.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={136.5} r={1.5} />
    <circle cx={154.5} cy={523.5} r={1.5} />
    <circle cx={532.5} cy={496.5} r={1.5} />
    <circle cx={127.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={127.5} r={1.5} />
    <circle cx={163.5} cy={532.5} r={1.5} />
    <circle cx={532.5} cy={505.5} r={1.5} />
    <circle cx={127.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={127.5} r={1.5} />
    <circle cx={154.5} cy={532.5} r={1.5} />
    <circle cx={550.5} cy={442.5} r={1.5} />
    <circle cx={109.5} cy={217.5} r={1.5} />
    <circle cx={442.5} cy={109.5} r={1.5} />
    <circle cx={217.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={451.5} r={1.5} />
    <circle cx={109.5} cy={208.5} r={1.5} />
    <circle cx={451.5} cy={109.5} r={1.5} />
    <circle cx={208.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={460.5} r={1.5} />
    <circle cx={109.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={109.5} r={1.5} />
    <circle cx={199.5} cy={550.5} r={1.5} />
    <circle cx={541.5} cy={460.5} r={1.5} />
    <circle cx={118.5} cy={199.5} r={1.5} />
    <circle cx={460.5} cy={118.5} r={1.5} />
    <circle cx={199.5} cy={541.5} r={1.5} />
    <circle cx={532.5} cy={478.5} r={1.5} />
    <circle cx={127.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={127.5} r={1.5} />
    <circle cx={181.5} cy={532.5} r={1.5} />
    <circle cx={550.5} cy={469.5} r={1.5} />
    <circle cx={109.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={109.5} r={1.5} />
    <circle cx={190.5} cy={550.5} r={1.5} />
    <circle cx={550.5} cy={478.5} r={1.5} />
    <circle cx={109.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={109.5} r={1.5} />
    <circle cx={181.5} cy={550.5} r={1.5} />
    <circle cx={541.5} cy={469.5} r={1.5} />
    <circle cx={118.5} cy={190.5} r={1.5} />
    <circle cx={469.5} cy={118.5} r={1.5} />
    <circle cx={190.5} cy={541.5} r={1.5} />
    <circle cx={541.5} cy={478.5} r={1.5} />
    <circle cx={118.5} cy={181.5} r={1.5} />
    <circle cx={478.5} cy={118.5} r={1.5} />
    <circle cx={181.5} cy={541.5} r={1.5} />
    <circle cx={505.5} cy={505.5} r={1.5} />
    <circle cx={154.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={154.5} r={1.5} />
    <circle cx={154.5} cy={505.5} r={1.5} />
    <circle cx={514.5} cy={496.5} r={1.5} />
    <circle cx={145.5} cy={163.5} r={1.5} />
    <circle cx={496.5} cy={145.5} r={1.5} />
    <circle cx={163.5} cy={514.5} r={1.5} />
    <circle cx={514.5} cy={505.5} r={1.5} />
    <circle cx={145.5} cy={154.5} r={1.5} />
    <circle cx={505.5} cy={145.5} r={1.5} />
    <circle cx={154.5} cy={514.5} r={1.5} />
    <circle cx={397.5} cy={568.5} r={1.5} />
    <circle cx={262.5} cy={91.5} r={1.5} />
    <circle cx={568.5} cy={262.5} r={1.5} />
    <circle cx={91.5} cy={397.5} r={1.5} />
    <circle cx={397.5} cy={577.5} r={1.5} />
    <circle cx={262.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={262.5} r={1.5} />
    <circle cx={82.5} cy={397.5} r={1.5} />
    <circle cx={397.5} cy={586.5} r={1.5} />
    <circle cx={262.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={262.5} r={1.5} />
    <circle cx={73.5} cy={397.5} r={1.5} />
    <circle cx={343.5} cy={577.5} r={1.5} />
    <circle cx={316.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={316.5} r={1.5} />
    <circle cx={82.5} cy={343.5} r={1.5} />
    <circle cx={343.5} cy={586.5} r={1.5} />
    <circle cx={316.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={316.5} r={1.5} />
    <circle cx={73.5} cy={343.5} r={1.5} />
    <circle cx={370.5} cy={577.5} r={1.5} />
    <circle cx={289.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={289.5} r={1.5} />
    <circle cx={82.5} cy={370.5} r={1.5} />
    <circle cx={370.5} cy={586.5} r={1.5} />
    <circle cx={289.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={289.5} r={1.5} />
    <circle cx={73.5} cy={370.5} r={1.5} />
    <circle cx={388.5} cy={577.5} r={1.5} />
    <circle cx={271.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={271.5} r={1.5} />
    <circle cx={82.5} cy={388.5} r={1.5} />
    <circle cx={388.5} cy={586.5} r={1.5} />
    <circle cx={271.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={271.5} r={1.5} />
    <circle cx={73.5} cy={388.5} r={1.5} />
    <circle cx={334.5} cy={577.5} r={1.5} />
    <circle cx={325.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={325.5} r={1.5} />
    <circle cx={82.5} cy={334.5} r={1.5} />
    <circle cx={334.5} cy={586.5} r={1.5} />
    <circle cx={325.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={325.5} r={1.5} />
    <circle cx={73.5} cy={334.5} r={1.5} />
    <circle cx={361.5} cy={577.5} r={1.5} />
    <circle cx={298.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={298.5} r={1.5} />
    <circle cx={82.5} cy={361.5} r={1.5} />
    <circle cx={361.5} cy={586.5} r={1.5} />
    <circle cx={298.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={298.5} r={1.5} />
    <circle cx={73.5} cy={361.5} r={1.5} />
    <circle cx={379.5} cy={577.5} r={1.5} />
    <circle cx={280.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={280.5} r={1.5} />
    <circle cx={82.5} cy={379.5} r={1.5} />
    <circle cx={379.5} cy={586.5} r={1.5} />
    <circle cx={280.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={280.5} r={1.5} />
    <circle cx={73.5} cy={379.5} r={1.5} />
    <circle cx={352.5} cy={577.5} r={1.5} />
    <circle cx={307.5} cy={82.5} r={1.5} />
    <circle cx={577.5} cy={307.5} r={1.5} />
    <circle cx={82.5} cy={352.5} r={1.5} />
    <circle cx={352.5} cy={586.5} r={1.5} />
    <circle cx={307.5} cy={73.5} r={1.5} />
    <circle cx={586.5} cy={307.5} r={1.5} />
    <circle cx={73.5} cy={352.5} r={1.5} />
  </svg>
);
