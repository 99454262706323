import styled from "styled-components";
import { defaults } from "theme";

export const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
  z-index: 5;

  @media (max-width: ${defaults.breakPoint}) {
    > div {
      padding: 0;
    }
  }
`;

export const HeaderInner = styled.div`
  height: 84px;
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 20px 20px 20px 25px;

    > a {
      position: relative;
      z-index: 15;
    }
  }
`;
