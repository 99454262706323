import { Helmet } from "react-helmet";
import { parseMetaTitle } from "utils/meta";

export type MetaProps = {
  title: string;
  description?: string;
};

export const Meta = ({ title, description }: MetaProps) => {
  return (
    <Helmet>
      <title>{parseMetaTitle({ title })}</title>
      {description && <meta name="description" content={description} />}
    </Helmet>
  );
};
