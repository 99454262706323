export const SvgLogo = () => (
  <svg
    width={144}
    height={40}
    viewBox="0 0 144 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.834 12.763a6.912 6.912 0 0 0-5.35 2.53L13.389 9.417c.31-.793.468-1.637.468-2.488a6.928 6.928 0 1 0-8.033 6.839v11.857a6.923 6.923 0 1 0 8.033 6.839c0-.851-.159-1.695-.468-2.488l10.084-5.887a6.928 6.928 0 1 0 5.36-11.326ZM2.21 6.929a4.735 4.735 0 1 1 9.469 0 4.735 4.735 0 0 1-9.47 0Zm4.734 30.248a4.735 4.735 0 1 1 3.343-1.38 4.734 4.734 0 0 1-3.358 1.38h.015Zm5.335-9.116a6.923 6.923 0 0 0-4.246-2.436V13.762a6.923 6.923 0 0 0 4.246-2.435l10.084 5.876a6.776 6.776 0 0 0 0 4.977L12.28 28.06Zm16.555-3.646a4.734 4.734 0 1 1 0-9.469 4.734 4.734 0 0 1 0 9.47Z"
      fill="url(#svgLogo_a)"
    />
    <path
      d="M60.413 27.214H48.577V6.57H45.24v24.03h15.172v-3.387ZM66.142 13.068h-3.388v17.587h3.388V13.067ZM87.42 12.99a7.26 7.26 0 0 0-5.381 2.409l-.332.368-.347-.358a7.78 7.78 0 0 0-5.66-2.42h-6.623v17.66h3.388V16.377H75.7a4.466 4.466 0 0 1 4.466 4.456v9.832h3.377V20.238a3.866 3.866 0 0 1 7.728 0v10.427h3.388V20.238a7.26 7.26 0 0 0-7.239-7.249ZM62.754 8.328a1.694 1.694 0 1 0 3.387-.01 1.694 1.694 0 0 0-3.387.01ZM104.848 13.063h-3.945V6.57h-3.388v24.03h7.365a8.769 8.769 0 0 0 8.769-8.769 8.769 8.769 0 0 0-8.769-8.77h-.032Zm0 14.15h-3.945V16.452h3.972a5.381 5.381 0 0 1 0 10.763h-.027ZM134.745 17.76v12.841h3.392v-12.84c0-.765.619-1.384 1.384-1.384h4.466v-3.388h-4.466a4.78 4.78 0 0 0-4.776 4.771ZM129.31 25.567a6.919 6.919 0 0 1-4.85 1.989 5.518 5.518 0 0 1-5.539-4.993h13.782v-.152c-.068-5.855-3.382-9.632-8.448-9.632-4.866 0-8.68 3.919-8.68 8.943a8.703 8.703 0 0 0 8.885 8.943c2.611.034 5.123-1 6.955-2.862l.1-.105-2.073-2.262-.132.131Zm-10.211-5.876a5.167 5.167 0 0 1 5.003-3.94h.084c2.568 0 4.467 1.536 4.898 3.94h-9.985Z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="svgLogo_a"
        x1={1.41}
        y1={29.776}
        x2={19.004}
        y2={15.273}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6D8AFD" />
        <stop offset={1} stopColor="#6D38FD" />
      </linearGradient>
    </defs>
  </svg>
);
