import { email, address, linkedIn } from "utils/info";
import { Meta } from "components/Meta";
import { Container } from "components/Container";
import { HeadingTwo, Text, GradientText } from "components/Typography";
import {
  Info,
  InfoIntro,
  InfoGrid,
  InfoGridItem,
  ContactSubHeading,
  ContactText,
} from "./style";

export const Contact = () => (
  <main data-testid="contact-page">
    <Meta
      title="Contact"
      description="If you'd like to learn more about how Limber can help you with your integration and analytics needs, get in touch – we'd love to hear from you."
    />
    <Info data-testid="contact-info">
      <Container>
        <InfoIntro>
          <HeadingTwo as="h1">Questions? We&apos;re here to help.</HeadingTwo>
          <Text>
            If you&#39;d like to learn more about how Limber can help you with
            your integration and analytics needs, get in touch &#8211; we&#39;d
            love to hear from you.
          </Text>
        </InfoIntro>
        <InfoGrid>
          <div>
            <InfoGridItem>
              <ContactSubHeading as="h2">
                <GradientText>Email us</GradientText>
              </ContactSubHeading>
              <ContactText>
                <a href={`mailto:${email}`}>{email}</a>
              </ContactText>
            </InfoGridItem>
            <InfoGridItem>
              <ContactSubHeading as="h2">
                <GradientText>Social</GradientText>
              </ContactSubHeading>
              <ContactText>
                <a href={linkedIn} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </ContactText>
            </InfoGridItem>
          </div>
          <InfoGridItem>
            <ContactSubHeading as="h2">
              <GradientText>Physical address</GradientText>
            </ContactSubHeading>
            <ContactText style={{ whiteSpace: "break-spaces" }}>
              {address}
            </ContactText>
          </InfoGridItem>
        </InfoGrid>
      </Container>
    </Info>
  </main>
);
