export const defaults = {
  fontFamilyHeading: '"Questrial", sans-serif',
  fontFamilyBody: '"Roboto", sans-serif',
  fontSize: "17px",
  lineHeight: 1.8,
  fontWeight: 400,
  transition: "225ms",
  borderRadius: "2px",
  focusBoxShadow: "0 0 0 2px rgba(0, 0, 0, 0.15)",
  focusBoxShadowWhite: "0 0 0 2px rgba(255, 255, 255, 0.15)",
  breakPoint: "1170px",
  breakPointMd: "800px",
};
