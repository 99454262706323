import styled from "styled-components";
import { defaults } from "theme";

export const HeadingTwo = styled.h2`
  font-family: ${defaults.fontFamilyHeading};
  font-size: 30px;
  font-weight: 400;
  line-height: 1.6;
  color: #fff;
  margin: 0 0 26px;

  @media (max-width: ${defaults.breakPoint}) {
    font-size: 26px;
    line-height: 1.6;
  }
`;
