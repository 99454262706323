import { createGlobalStyle } from "styled-components";
import { defaults } from "./defaults";
import { colors } from "./colors";

export const ThemeDefaults = createGlobalStyle`
  * {
    box-sizing: border-box;

    &::selection {
      color: #fff;
      background-color: ${colors.purple};
    }
  }

  html {
    font-family: ${defaults.fontFamilyBody};
    font-size: ${defaults.fontSize};
    line-height: ${defaults.lineHeight};
    font-weight: ${defaults.fontWeight};
    color: ${colors.text};
    background-color: #1C0E36;
    -webkit-font-smoothing: antialiased;
  }

  html > body {
    overflow: auto;
  }

  svg {
    display: block;
  }

  @media (max-width: ${defaults.breakPoint}) {
    // Disable body scrolling if mobile nav is active
    body.mobile-nav-active {
      overflow: hidden;
    }
  }
`;

export { defaults } from "./defaults";
export { colors } from "./colors";
