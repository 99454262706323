import { useLocation } from "react-router-dom";
import { email, addressShort, linkedIn } from "utils/info";
import { Container } from "components/Container";
import { Logo } from "components/Logo";
import {
  FooterContainer,
  FooterInner,
  FooterLogo,
  FooterText,
  FooterInfo,
  FooterCopyright,
} from "./style";

export const Footer = () => {
  const location = useLocation();
  const isContactPage = location.pathname === "/contact";

  const getCurrYear = () => {
    const dateNow = new Date();
    return dateNow.getFullYear().toString();
  };

  return (
    <FooterContainer data-testid="footer">
      <Container>
        <FooterInner $isContactPage={isContactPage}>
          <FooterLogo>
            <Logo type="icon" data-testid="footer-logo" />
          </FooterLogo>
          {/* Do not show info block on contact page. */}
          {!isContactPage ? (
            <FooterInfo>
              <FooterText>{addressShort}</FooterText>
              <FooterText>
                <a href={`mailto:${email}`}>{email}</a>
              </FooterText>
              <FooterText>
                <a href={linkedIn} target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </FooterText>
            </FooterInfo>
          ) : null}
          <FooterCopyright>
            <FooterText>
              &copy; {getCurrYear()} Limber. All Rights Reserved.
            </FooterText>
          </FooterCopyright>
        </FooterInner>
      </Container>
    </FooterContainer>
  );
};
