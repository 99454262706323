import styled from "styled-components";
import { defaults } from "theme";
import { HeadingThree, Text } from "components/Typography";
import stars from "images/stars_2x.png";
import squareDots from "images/square_dots_sm_2x.png";
import circleDots from "images/circle_dots_sm_2x.png";

export const Hero = styled.section`
  height: 625px;
  background-color: #231244;
  text-align: center;
  display: flex;
  align-items: center;
  background-image: url(${stars});
  background-position: 50% bottom;
  background-size: 2048px 782px;
  background-repeat: no-repeat;

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 100px 0 40px;
    background-position: center center;
  }
`;

export const HeroInner = styled.div`
  max-width: 750px;
  padding-top: 35px;

  @media (max-width: ${defaults.breakPoint}) {
    padding-top: 0;
  }
`;

export const Jobs = styled.section`
  padding: 125px 0 175px;
  background-color: #1c0e36;
  text-align: center;
  display: flex;
  align-items: center;

  @media (max-width: ${defaults.breakPoint}) {
    padding: 65px 0 75px;
  }
`;

export const JobsIntro = styled.div`
  max-width: 750px;
`;

export const JobsGrid = styled.div`
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 60px;
  row-gap: 60px;

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 50px;
    row-gap: 50px;
  }

  @media (max-width: ${defaults.breakPointMd}) {
    grid-template-columns: 1fr;
    text-align: center;
    column-gap: 0;
    row-gap: 50px;
  }
`;

export const JobsGridItem = styled.div``;

export const JobHeading = styled(HeadingThree)`
  margin: 0 0 12px;
`;

export const JobDescription = styled(Text)`
  font-size: 16px;
`;

export const JobsLinkContainer = styled(Text)`
  margin-top: 15px;
`;

export const JobLink = styled.a`
  font-family: ${defaults.fontFamilyHeading};
`;

export const Banner = styled.section`
  height: 500px;
  background-color: #281a4b;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: calc(50% - 950px);
    top: -175px;
    background-image: url(${circleDots});
    width: 464px;
    height: 464px;
    background-size: 464px 464px;
    background-position: 0 0;
  }

  &::after {
    content: "";
    position: absolute;
    right: calc(50% - 950px);
    bottom: -125px;
    background-image: url(${squareDots});
    width: 434px;
    height: 434px;
    background-size: 434px 434px;
    background-position: 0 0;
  }

  @media (max-width: ${defaults.breakPoint}) {
    height: auto;
    padding: 65px 0 80px;

    &::before {
      left: -300px;
      top: -300px;
    }

    &::after {
      right: -300px;
      bottom: -300px;
    }
  }
`;

export const BannerInner = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }
`;

export const BannerButton = styled.div`
  margin-top: 50px;

  @media (max-width: ${defaults.breakPoint}) {
    margin-top: 45px;
  }
`;

export const HeartContainer = styled.div`
  display: flex;
  justify-content: center;

  > * {
    display: flex;
    align-items: center;
  }

  svg {
    width: 50px;
    height: 55px;
  }
`;
