import styled from "styled-components";
import { colors } from "theme";

export const GradientText = styled.span`
  background: linear-gradient(
    to right,
    ${colors.gradientBlue},
    ${colors.gradientPurple} 150%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0, 0, 0, 0);
`;
