import { Meta } from "components/Meta";
import { Container } from "components/Container";
import {
  HeadingOne,
  HeadingTwo,
  HeadingThree,
  Text,
  GradientText,
} from "components/Typography";
import { ButtonLink } from "components/Button";
import {
  Hero,
  HeroInner,
  About,
  AboutIntro,
  AboutGrid,
  AboutGridItem,
  AboutGridIcon,
  Banner,
  BannerButton,
} from "./style";
import imgIconNodes from "images/icon_nodes_2x.png";
import imgIconHexagons from "images/icon_hexagons_2x.png";
import imgIconCubes from "images/icon_cubes_2x.png";
import imgIconLayers from "images/icon_layers_2x.png";

export const Home = () => (
  <main data-testid="home-page">
    <Meta
      title="Home"
      description="Liberate your data with Limber's unified data store that connects all your SaaS, on-premises, and cloud-hosted applications; and provides your favourite analytics tools with high-quality data on demand."
    />
    <Hero data-testid="home-hero">
      <Container>
        <HeroInner>
          <HeadingOne>Liberate your data with Limber.</HeadingOne>
          <Text>
            Your data is one of your most valuable assets. Here at Limber,
            we&#39;ve created a next-generation platform to help you realise
            that value. At the heart of Limber is a unified data store that
            connects all your SaaS, on-premises, and cloud-hosted applications;
            and provides your favourite analytics tools with high-quality data
            on demand.
          </Text>
        </HeroInner>
      </Container>
    </Hero>
    <About data-testid="home-about">
      <Container>
        <AboutIntro>
          <HeadingTwo>
            Learn how Limber can transform the way you manage your data.
          </HeadingTwo>
        </AboutIntro>
        <AboutGrid>
          <AboutGridItem>
            <AboutGridIcon>
              <img
                src={imgIconLayers}
                alt="We know data"
                width={133}
                height={103}
                style={{
                  position: "relative",
                  left: "-15px",
                }}
              />
            </AboutGridIcon>
            <HeadingThree>
              <GradientText>We know data.</GradientText>
            </HeadingThree>
            <Text>
              Our team has vast experience in working with data across some of
              the world&#39;s best organisations. We&#39;ve used that experience
              to build a modern integration solution that&#39;s easy to use and
              fit for the future.
            </Text>
          </AboutGridItem>
          <AboutGridItem>
            <AboutGridIcon>
              <img
                src={imgIconNodes}
                alt="Get insights, fast"
                width={133}
                height={103}
                style={{
                  position: "relative",
                  left: "-20px",
                }}
              />
            </AboutGridIcon>
            <HeadingThree>
              <GradientText>Get insights, fast.</GradientText>
            </HeadingThree>
            <Text>
              Leveraging your data to gain valuable business insights is easy
              with Limber. Your data is already collated and conformed in our
              unified data store, ready for you to consume in any analytics
              solution you choose.
            </Text>
          </AboutGridItem>
          <AboutGridItem>
            <AboutGridIcon>
              <img
                src={imgIconCubes}
                alt="Data migration and integration"
                width={133}
                height={103}
                style={{
                  position: "relative",
                  left: "-13px",
                }}
              />
            </AboutGridIcon>
            <HeadingThree>
              <GradientText>Data migration and integration.</GradientText>
            </HeadingThree>
            <Text>
              Migrating to new enterprise applications doesn&#39;t have to be a
              time-consuming and expensive process anymore. Limber solves your
              data migration and integration needs and can allow you to stagger
              migration away from complex legacy applications by acting as a
              bridge between the old and the new.
            </Text>
          </AboutGridItem>
          <AboutGridItem>
            <AboutGridIcon>
              <img
                src={imgIconHexagons}
                alt="It's your data"
                width={133}
                height={103}
                style={{
                  position: "relative",
                  left: "-15px",
                }}
              />
            </AboutGridIcon>
            <HeadingThree>
              <GradientText>It&#39;s your data.</GradientText>
            </HeadingThree>
            <Text>
              No one should own your valuable data but you. Limber&#39;s unified
              data store allows you to retain your data independently of your
              applications, so you never have to hold on to an application past
              its use-by date, just for its data.
            </Text>
          </AboutGridItem>
        </AboutGrid>
      </Container>
    </About>
    <Banner data-testid="home-banner">
      <Container>
        <HeadingTwo>Want to know more?</HeadingTwo>
        <Text>Get in touch to learn how Limber can help your business.</Text>
        <BannerButton>
          <ButtonLink to="/contact">Contact Us</ButtonLink>
        </BannerButton>
      </Container>
    </Banner>
  </main>
);
